import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css

import RedCross from './img/redcross.png';
import GreenCheck from './img/greencheck.png';

import Application from './Components/Application';
import UserProvider from './Providers/UserProvider';

export function handleBanUserModal(show) {
  let banUserModal = document.getElementById('banUserModal');
  if (!show) {
    banUserModal.classList.add('hide-ban-user');
  } else {
    banUserModal.classList.remove('hide-ban-user');
  }
}

export function handleOnlineUserModal(show) {
  let onlineUserModal = document.getElementById('onlineUserModal');
  if (!show) {
    onlineUserModal.classList.add('hide-ban-user');
  } else {
    onlineUserModal.classList.remove('hide-ban-user');
  }
}

export function handleRechargeUserModal(show) {
  let banUserModal = document.getElementById('recharge-user-model');
  if (!show) {
    banUserModal.classList.add('hide-recharge-user');
  } else {
    banUserModal.classList.remove('hide-recharge-user');
  }
}

export function loadingSpinner(show) {
  const loadingElement = document.getElementById('loadingSpinner');
  if (loadingElement) loadingElement.style.display = show ? 'block' : 'none';
}

export function spinner(show) {
  if (document.getElementById('loadSpinner') != null) {
    document.getElementById('loadSpinner').style.display = show
      ? 'block'
      : 'none';
    document.body.style.overflow = show ? 'hidden' : 'auto';
  }

  if (!show) {
    document.getElementById('popupHeader').innerHTML = '';
    document.getElementById('popupMessage').innerHTML = '';
    document.getElementById('modalPopup').classList.add('closed');
  }
}

export function showConfirmDialog(error, message) {
  if (document.getElementById('popupImg') != null) {
    document.getElementById('popupImg').src = error ? RedCross : GreenCheck;
    document.getElementById('popupHeader').innerHTML = error
      ? 'Error!'
      : 'Success!';
    document.getElementById('popupMessage').innerHTML = message;
    document.getElementById('modalPopup').classList.remove('closed');
  }
}

export function calculateRating(totalRating, numberOfRatings) {
  if (numberOfRatings === 0) return 0;
  return totalRating / numberOfRatings;
}

function App() {
  return (
    <UserProvider>
      <Application />
    </UserProvider>
  );
}

export default App;
