import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';

import { firestore } from '../../Firebase';
import { loadingSpinner } from '../../App';

export default class Faqs extends Component{

  constructor(props){
    super(props)
    this.state = {
      faqType: 'general',
      data: []
    }
  }

  fetchData(){
    firestore.collection('faqs').where("type", "==", this.state.faqType).get().then((docSnap) => {
      let faqList = [];
      docSnap.forEach((doc) => {
        var faqData = {
          faqId: doc.id,
          faqData: doc.data(),
        }
        faqList.push(faqData);
      });
      this.setState({
        data: faqList
      });
      this.structureData();
      console.log(faqList);
    });
  }

  structureData(){
    const postData = this.state.data.map((pd, key) =>
      <React.Fragment key={key}>
        <Link
          to={{
            pathname: `/FAQs/edit/${pd.faqId}`
          }}
          style={{textDecoration: 'none', color: "#505050"}}
          className="faq-list-item">
          <div className="faq-list-item-entry w30"><b><p>{pd.faqData.title}</p></b></div>
          <div className="faq-list-item-entry w70"><p>{pd.faqData.content}</p></div>
        </Link>
      </React.Fragment>
    )
    this.setState({
      postData
    })
  }

  filterListingType(filter){
    let generalBtn = document.getElementById('generalBtn');
    let senderBtn = document.getElementById('senderBtn');
    let travellerBtn = document.getElementById('travellerBtn');
    switch (filter) {
      case 'general':
        generalBtn.classList.add('active');
        senderBtn.classList.remove('active')
        travellerBtn.classList.remove('active')
        break;
      case 'sender':
        generalBtn.classList.remove('active');
        senderBtn.classList.add('active')
        travellerBtn.classList.remove('active')
        break;
      case 'traveller':
        generalBtn.classList.remove('active');
        senderBtn.classList.remove('active')
        travellerBtn.classList.add('active')
        break;
      default:
    }
    this.setState({
      faqType: filter
    }, () => {
      this.fetchData();
    })
  }

  componentDidMount(){
    this.fetchData();
  }

  render(){
    return(
      <div style={{padding: "30px"}}>
        <Helmet>
          <title>Trippie Air | FAQ's List</title>
        </Helmet>
        <div>
          <h1 className="control-title">
            Frequently Asked Questions List
          </h1>
          <div className="report-list-content">
            <div className="report-filter-container">
              <div className="filter-listing-type">
                <div id="generalBtn" onClick={() => this.filterListingType('general')} className="w33 filter-listing-type-btn border-top-left active">
                  <i className="fas fa-home"></i>General
                </div>
                <div id="senderBtn" onClick={() => this.filterListingType('sender')} className="w33 filter-listing-type-btn border-top-right">
                  <i className="fas fa-box"></i>Sender
                </div>
                <div id="travellerBtn" onClick={() => this.filterListingType('traveller')} className="w33 filter-listing-type-btn border-top-right">
                  <i className="fas fa-plane"></i>Traveller
                </div>
              </div>
            </div>
            <Link
              to='/FAQs/create'
              style={{textDecoration: 'none'}}
            >
              <div className="w100 filter-listing-type-btn border-top-right" style={{marginBottom: '10px'}}>
                <i className="fas fa-plus-circle"></i>Add new FAQ
              </div>
            </Link>
            <div className="notifications-list-table">
              <div className="notifications-list-header">
                <div className="notifications-list-header-title w30">Title</div>
                <div className="notifications-list-header-title w70">Content</div>
              </div>
            </div>
            {this.state.postData}
          </div>
        </div>
      </div>
    )
  }
}
