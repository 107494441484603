import { firestore } from '../Firebase';
import { getDeliveryRequestInfo } from './delivery-services';

export const companyInfoById = async (companyId) => {
  const companies = await getDeliveryRequestInfo('companies', companyId);
  return companies;
};

export const updateCompanyProfile = async (companyId, updateData) => {
  const docRef = firestore.collection('companies').doc(companyId);

  try {
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      await docRef.update(updateData);
      console.log('Document updated successfully');
      return 'Update Successfully';
    } else {
      await docRef.set(updateData, { merge: true });
      console.log('Document created and updated successfully');
      return 'Document created and updated successfully';
    }
  } catch (error) {
    console.error('Error updating company profile:', error);
    return 'Error updating company profile';
  }
};
