import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Form } from "react-bootstrap";
import { auth, firestore } from "../../Firebase";

export default class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.searchTimer = null; // Add timer reference
    this.state = {
      data: [],
      allData: [],
      perPage: 10,
      currentPage: 0,
      offset: 0,
      search: "",
      type: "all",
    };
  }

  handleSearch = (e) => {
    const searchValue = e.target.value;
    this.setState({ search: searchValue, currentPage: 0, offset: 0 });

    // Clear existing timer
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    // Set new timer
    this.searchTimer = setTimeout(() => {
      this.doFilter(this.state.allData);
    }, 500);
  };

  fetchData() {
    this.listener = firestore
      .collectionGroup("requests_stored")
      .where("status", "==", 1)
      .orderBy("creationDate", "desc")
      .onSnapshot((querySelector) => {
        let tripList = [];
        querySelector.forEach((doc) => {
          var tripData = {
            tripId: doc.id,
            tripData: doc.data(),
          };
          tripList.push(tripData);
        });
        this.setState({ allData: tripList });
        this.doFilter(tripList);
      });
  }

  doFilter(allData) {
    const search = this.state.search.toLowerCase();
    const type = this.state.type;
    let tripList = [...allData];

    if (type !== "all") {
      tripList = tripList.filter((trip) => {
        return (
          trip.tripData.isUrgent === (type === "urgent" ? "true" : "false")
        );
      });
    }
    if (this.state.search) {
      tripList = tripList.filter((trip) => {
        return trip.tripId.toLowerCase().includes(search);
      });
    }

    this.setState({ data: tripList }, () => {
      this.paginateData();
    });
  }

  componentDidMount() {
    this.fetchData();
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  componentWillUnmount() {
    if (this.listener) {
      this.listener();
    }
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  paginateData() {
    const slice = this.state.data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    const postData = slice.map((pd, key) => (
      <React.Fragment key={key}>
        <Link
          to={{
            pathname: `/TripDetails/${pd.tripId}/${pd.tripData.senderId}`,
          }}
          style={{ textDecoration: "none", color: "#505050" }}
          className="trip-list-item-line"
        >
          <div className="trip-list-item-entry">
            <img src={pd.tripData.imageUri} alt="thumbnail" />
          </div>
          <div className="trip-list-item-entry">{pd.tripId}</div>
          <div className="trip-list-item-entry">
            {pd.tripData.senderDisplayName}
          </div>
          <div className="trip-list-item-entry">
            {`${pd.tripData.pickUpAddress.street}, 
            ${pd.tripData?.pickUpAddress?.city}, 
            ${pd.tripData?.pickUpAddress.postcode}`}
          </div>
          <div className="trip-list-item-entry">
            {`${pd.tripData.dropOffAddress.street}, 
            ${pd.tripData?.dropOffAddress?.city}, 
            ${pd.tripData?.dropOffAddress.postcode}`}
          </div>
          <div className="trip-list-item-entry">
            {pd.tripData.creationDate
              ? new Date(
                  pd.tripData.creationDate.seconds * 1000 +
                    pd.tripData.creationDate.nanoseconds / 1e6
                ).toLocaleString("en-NZ")
              : "---"}
          </div>
          <div className="trip-list-item-entry">
            <div
              style={{ color: pd.tripData?.isUrgent === "true" ? "red" : "" }}
            >
              {pd.tripData.isUrgent === "true" ? "U" : "R"}
            </div>
          </div>
        </Link>
      </React.Fragment>
    ));
    this.setState({
      pageCount: Math.ceil(this.state.data.length / this.state.perPage),
      postData,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.paginateData();
      }
    );
  };

  filterByType = (type) => {
    this.setState({ type }, () => {
      this.doFilter(this.state.allData);
    });
  };

  render() {
    return (
      <div className="" style={{ padding: "30px" }}>
        <Helmet>
          <title>Trippie Air | Authorise new Deliveries</title>
          <meta
            name="description"
            content="Trippie Air | Authorise new trips"
          />
        </Helmet>
        <h1 className="control-title">Authorise Customer Deliveries</h1>
        <div className="trip-list">
          <div className="change-trip-display-container">
            <div className="change-buttons">
              <div
                id="tripListAll"
                className={`change-trip-display-button noselect ${
                  this.state.type === "all" ? "active" : ""
                }`}
                onClick={() => this.filterByType("all")}
              >
                All
              </div>
              <div
                id="tripListActive"
                className={`change-trip-display-button noselect ${
                  this.state.type === "urgent" ? "active" : ""
                }`}
                onClick={() => this.filterByType("urgent")}
              >
                Urgent
              </div>
              <div
                id="tripListHistorical"
                className={`change-trip-display-button noselect ${
                  this.state.type === "regular" ? "active" : ""
                }`}
                onClick={() => this.filterByType("regular")}
              >
                Regular
              </div>
            </div>
            <Form.Control
              type="text"
              value={this.state.search}
              placeholder="Search Trips by ID"
              className="search-user"
              onChange={this.handleSearch}
            />
          </div>
          <div className="trip-list-table">
            <div className="trip-list-table-head">
              <div className="trip-list-table-head-title">Thumbnail</div>
              <div className="trip-list-table-head-title">Delivery ID</div>
              <div className="trip-list-table-head-title">
                Sender Display Name
              </div>
              <div className="trip-list-table-head-title"> Pickup Address</div>
              <div className="trip-list-table-head-title">Dropoff Address</div>
              <div className="trip-list-table-head-title">Creation Date</div>
              <div className="trip-list-table-head-title">Urgent</div>
            </div>
            <div className="trip-list-table-body">
              <div
                className="trip-list-table-item"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {this.state.postData}
              </div>
            </div>
          </div>
          <div className="pagination-container no-shadow">
            <ReactPaginate
              previousLabel={<i className="fas fa-arrow-left"></i>}
              nextLabel={<i className="fas fa-arrow-right"></i>}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    );
  }
}
