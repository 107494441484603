import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {FaTruckPickup} from "react-icons/fa";
import {TbTruckDelivery} from "react-icons/tb";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import {loadingSpinner} from "../../App";
import {
    REQUEST_ACCEPTED,
    REQUEST_ASSIGNED,
    REQUEST_CANCELLED,
    REQUEST_COMPLETE,
    REQUEST_DELIVERED,
    REQUEST_FINALISED,
    REQUEST_PICKED_UP,
    REQUEST_REJECT,
} from "../../constants";
import {firestore} from "../../Firebase";
import {formattedDeliveryAmount, getStatus} from "../../utils/helper";
import LoadingSpinner from "../ModalComponents/LoadingSpinner";
import {set} from "react-hook-form";

const Delivery = () => {
    const [pagination, setPagination] = useState({
        offset: 0,
        data: [],
        perPage: 6,
        currentPage: 0,
        pageCount: 0,
        postData: [],
    });

    const [activeTap, setActiveTap] = useState(2);
    const [pageTitle, setPageTitle] = useState("Active Deliveries");
    const [search, setSearch] = useState("");
    const [deliveryType, setDeliveryType] = useState("all"); // Add state

    const [listData, setListData] = useState([]);

    // Add filter buttons UI
    const filterButtons = () => (
        <div className="change-buttons">
            <div
                id="tripListAll"
                className={`change-trip-display-button noselect ${
                    deliveryType === "all" ? "active" : ""
                }`}
                onClick={() => setDeliveryType("all")}
            >
                All
            </div>
            <div
                id="tripListActive"
                className={`change-trip-display-button noselect ${
                    deliveryType === "urgent" ? "active" : ""
                }`}
                onClick={() => setDeliveryType("urgent")}
            >
                Urgent
            </div>
            <div
                id="tripListHistorical"
                className={`change-trip-display-button noselect ${
                    deliveryType === "regular" ? "active" : ""
                }`}
                onClick={() => setDeliveryType("regular")}
            >
                Regular
            </div>
        </div>
    );

    function fetchData(status = 2) {
        setActiveTap(status);
        switch (status) {
            case REQUEST_ACCEPTED:
                document.getElementById("tripListActive").classList.add("active");
                document
                    .getElementById("tripListHistorical")
                    .classList.remove("active");
                document.getElementById("tripListCancelled").classList.remove("active");
                break;
            case REQUEST_COMPLETE:
                document.getElementById("tripListActive").classList.remove("active");
                document.getElementById("tripListHistorical").classList.add("active");
                document.getElementById("tripListCancelled").classList.remove("active");
                break;
            case REQUEST_FINALISED:
                document.getElementById("tripListActive").classList.remove("active");
                document.getElementById("tripListHistorical").classList.add("active");
                document.getElementById("tripListCancelled").classList.remove("active");
                break;
            case REQUEST_CANCELLED:
                document.getElementById("tripListActive").classList.remove("active");
                document
                    .getElementById("tripListHistorical")
                    .classList.remove("active");
                document.getElementById("tripListCancelled").classList.add("active");
                break;
            default:
        }
        loadingSpinner(true);
        console.log(status, "status");
        let Include;

        if (status === REQUEST_COMPLETE) {
            Include = [REQUEST_COMPLETE, REQUEST_DELIVERED, REQUEST_FINALISED];
        }
        if (status === REQUEST_ACCEPTED) {
            Include = [REQUEST_ACCEPTED, REQUEST_ASSIGNED, REQUEST_PICKED_UP];
        }
        if (status === REQUEST_CANCELLED) {
            Include = [REQUEST_CANCELLED, REQUEST_REJECT];
        }

        firestore
            .collectionGroup("requests_stored")
            .where("status", "in", Include)
            .orderBy("lastUpdateDate", "desc")
            .onSnapshot(
                (querySnapshot) => {
                    let currentRequestsList = [];
                    querySnapshot.forEach((doc) => {
                        currentRequestsList.push({
                            data: doc.data(),
                            id: doc.id,
                        });
                    });

                    if (search) {
                        setPagination((prev) => ({
                            ...prev,
                            pageCount: 1,
                            offset: 0,
                        }));
                        currentRequestsList = currentRequestsList.filter((item) => {
                            return item.id.toLowerCase().includes(search.toLowerCase());
                        });
                    }

                    // Filter by delivery type
                    if (deliveryType !== "all") {
                        currentRequestsList = currentRequestsList.filter((item) =>
                            deliveryType === "urgent"
                                ? item.data.isUrgent == "true"
                                : item.data.isUrgent == "false"
                        );
                    }

                    setPagination((prev) => ({
                        ...prev,
                        data: [...currentRequestsList],
                    }));
                    loadingSpinner(false);
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                }
            );
    }

    function paginateData() {
        const slice = pagination?.data?.slice(
            pagination.offset,
            pagination.offset + pagination.perPage
        );

        const postData = slice.map((pd, key) => (
            <React.Fragment key={key}>
                <div className="active-trip-card-container">
                    <Link
                        to={{
                            pathname: `/Delivery/${pd.id}/${pd?.data.senderId}`,
                        }}
                        style={{
                            textDecoration: "none",
                            color: "#505050",
                            display: "flex",
                            flexDirection: "column",
                        }}
                        className="active-trip-card"
                    >
                        <div className="delivery-info-card">
                            <div>Delivery Id : {pd.id}</div>
                            <div>Item Name : {pd.data.itemName}</div>
                            <div>Fee : {formattedDeliveryAmount(pd.data)}</div>
                            {pd.data.status === REQUEST_CANCELLED && (
                                <div className="cancellation-reason">
                                    {`Cancelled By ${pd.data.cancelledBy}`}
                                </div>
                            )}
                        </div>

                        <div className="trip-details-active-trips">
                            <div className="drop-info">
                                <div className="depart-details">
                                    <div
                                        className={`urgent ${
                                            pd.data?.isUrgent === "true" ? "cancellation-reason" : ""
                                        }`}
                                    >
                                        {pd.data?.isUrgent === "true" ? "U" : "R"}
                                    </div>
                                    <div className="status-text">
                                        {pd?.data.status ? getStatus(pd?.data.status) : "N/A"}
                                    </div>

                                    <div className="details-img">
                                        <FaTruckPickup size={50} color="#01a2cc"/>
                                    </div>
                                    <div className="info">
                                        <div className="info-content bold-title">
                                            Pickup Address
                                        </div>
                                        <div
                                            className="info-content">{`${pd.data.pickUpAddress.street},${pd.data.pickUpAddress.city},${pd.data.pickUpAddress.postcode}`}</div>
                                        <div className="info-content">
                                            {pd?.data?.pickUpDateTime
                                                ?.toDate()
                                                ?.toLocaleString("en-NZ")}
                                        </div>
                                    </div>
                                </div>
                                <div className="arrive-details">
                                    <>
                                        <div className="details-img">
                                            <TbTruckDelivery size={50} color="#01a2cc"/>
                                        </div>
                                        <div className="info">
                                            <div className="info-content bold-title">
                                                Dropoff Address
                                            </div>

                                            <div className="info-content">
                                                {`${pd?.data?.dropOffAddress?.street},${pd?.data?.dropOffAddress?.city},${pd?.data?.dropOffAddress?.postcode}`}
                                            </div>
                                            <div className="info-content">
                                                {pd?.data?.dropDateAndTime
                                                    ?.toDate()
                                                    ?.toLocaleString("en-NZ")}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>

                            <img src={pd.data.imageUri} alt="delivery details"/>

                            {/* <div className="total-value">${pd?.data?.paidAmount}</div> */}
                        </div>
                        <div className="traveller-details-container">
                            <div className="traveller-details">
                                <div className="details-img">
                                    {pd?.data?.senderDisplayImg ? (
                                        <img
                                            className="user-profile-image"
                                            src={pd?.data?.senderDisplayImg}
                                            alt=""
                                        />
                                    ) : (
                                        <i className="far fa-user-circle"></i>
                                    )}
                                </div>
                                <div className="info">
                                    <div className="info-content bold-title">
                                        {pd?.data?.senderDisplayName}
                                    </div>

                                    <div className="info-content">
                                        Listed{" "}
                                        {pd?.data?.creationDate?.toDate()?.toLocaleString("en-NZ")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        ));
        setPagination((prev) => ({
            ...prev,
            pageCount: Math.ceil(pagination.data.length / pagination.perPage),
        }));
        setListData(postData);
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * pagination.perPage;
        setPagination((prev) => ({
            ...prev,
            currentPage: selectedPage,
            offset: offset,
        }));
        paginateData();
    };

    useEffect(() => {
        fetchData();
    }, [search]); //eslint-disable-line

    useEffect(() => {
        paginateData();
    }, [pagination.data]); //eslint-disable-line

    useEffect(() => {
        if (activeTap === REQUEST_ACCEPTED) {
            setPageTitle("Active Deliveries");
        } else if (activeTap === REQUEST_COMPLETE) {
            setPageTitle("Past Deliveries");
        } else if (activeTap === REQUEST_CANCELLED) {
            setPageTitle("Cancelled Deliveries");
        }
    }, [activeTap]); //eslint-disable-line

    useEffect(() => {
        fetchData(activeTap);
    }, [activeTap, deliveryType]);

    return (
        <div className="" style={{padding: "30px"}}>
            <Helmet>
                <title>Trippie Air | {pageTitle}</title>
                <meta name="description" content={`Trippie Air | ${pageTitle}`}/>
            </Helmet>
            <h1 className="control-title">{pageTitle}</h1>
            <div className="active-trip-list">
                <div className="change-trip-display-container">
                    <div className="change-buttons">
                        <div
                            id="tripListActive"
                            className="change-trip-display-button noselect active"
                            onClick={() => fetchData(2)}
                        >
                            Active
                        </div>
                        <div
                            id="tripListHistorical"
                            className="change-trip-display-button noselect"
                            onClick={() => fetchData(REQUEST_COMPLETE)}
                        >
                            Historic
                        </div>
                        <div
                            id="tripListCancelled"
                            className="change-trip-display-button noselect"
                            onClick={() => {
                                fetchData(REQUEST_CANCELLED);
                            }}
                        >
                            Cancelled
                        </div>
                    </div>

                    <Form.Control
                        type="text"
                        value={search}
                        placeholder="Search Deliveries by ID"
                        className="search-user"
                        onChange={(e) => setSearch(e.target.value)}
                        style={{maxWidth: "500px"}}
                    />
                </div>
                {filterButtons()}
                <div className="active-trip-container">
                    <LoadingSpinner/>
                    {listData}
                    <div className="pagination-container">
                        <ReactPaginate
                            previousLabel={<i className="fas fa-arrow-left"></i>}
                            nextLabel={<i className="fas fa-arrow-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagination.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Delivery;
