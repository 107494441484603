import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export default class TreeView extends Component {
  constructor(props){
    super(props);
    this.state = {
      title: props.data.title,
      icon: props.data.icon,
      itemsList: props.data.links,
      open: false,
      chevronId: props.data.chevronId
    };
  }


  constructTreeItem(){
    const postData = this.state.itemsList.map((data, j) => <React.Fragment key={j}>
      <li className="nav-item">
        <NavLink to={`/${data.replace(/\s/g, "")}`} className="nav-link">
          <i className="far fa-circle"></i>
          <span className="menu-item-text">{data}</span>
        </NavLink>
      </li>
    </React.Fragment>)
    this.setState({
      postData
    });
  }

  componentDidMount(){
    this.constructTreeItem();
  }

  toggleTree(){
    this.setState((prevState) => {
      return{
        ...prevState,
        open: !prevState.open
      }
    });
      document.getElementById(this.state.chevronId).style.transform = this.state.open ? "rotate(0deg)" :"rotate(180deg)";
  }

  render(){
    let treeStatusClassName = this.state.open ? "nav-treeview-open" : "nav-treeview-closed";
    return(
      <li className="nav-item has-treeview noselect">
        <div className="nav-link" onClick={() => this.toggleTree()}>
          <i style={{paddingTop : '11px', paddingBottom: '11px'}}className={this.state.icon}></i>
          <span className="menu-item-text">{this.state.title}</span>
          <span className="side-menu-expand-chevron"><i id={this.state.chevronId} className="fas fa-chevron-down"></i></span>
        </div>
        <ul className={treeStatusClassName}>
          {this.state.postData}
        </ul>
      </li>
    );
  }
}
