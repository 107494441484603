import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

import { auth, firestore } from '../../Firebase';

import { getDeliveryRequestInfo } from '../../services/delivery-services';
import { UserContext } from '../../Providers/UserProvider';
import { companyInfoById } from '../../services/logistic-services';
import Select from 'react-select';
import { fetchCompanies } from '../../services/user-services';
import { formatCurrency, haveValue } from '../../utils/helper';
import Loader from '../Loader';
import ShimmerLoader from '../ModalComponents/ShimmerLoading';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Earnings = () => {
  const userContext = useContext(UserContext);
  const [paymentData, setPaymentData] = useState([]);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [postData, setPostData] = useState([]);
  const [companyOptions, selectedCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let listener = null;

    const fetchPaymentsData = () => {
      setIsLoading(true);
      let paymentsRef = firestore
        .collection('payments')
        .orderBy('lastUpdated', 'desc');

      if (userContext.isThirdParty) {
        paymentsRef = paymentsRef.where(
          'companyId',
          '==',
          userContext.admin.company
        );
      }

      if (selectedCompany && selectedCompany.value) {
        if (selectedCompany.value === 'logistics') {
          paymentsRef = paymentsRef.where('companyId', 'not-in', ['null', '']);
        } else {
          paymentsRef = paymentsRef.where(
            'companyId',
            '==',
            selectedCompany.value
          );
        }
      }

      listener = paymentsRef.onSnapshot(
        async (querySnapshot) => {
          try {
            const result = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const paymentData = {
                  id: doc.id,
                  data: doc.data(),
                };

                try {
                  const deliveryRequest = await getDeliveryRequestInfo(
                    `delivery_requests/${paymentData.data.payeeUserId}/requests_stored`,
                    paymentData.id
                  );
                  const companyInfo = await companyInfoById(
                    paymentData.data.companyId
                  );

                  return {
                    ...paymentData,
                    data: {
                      ...paymentData.data,
                      deliveryRequest,
                      companyInfo,
                    },
                  };
                } catch (error) {
                  console.error(
                    `Error fetching delivery request for payment ${paymentData.id}:`,
                    error
                  );
                  return paymentData;
                }
              })
            );

            setIsLoading(false);

            setPageCount(Math.ceil(result.length / perPage));
            setPaymentData(result);
          } catch (error) {
            setIsLoading(false);
            console.error('Error processing payments data:', error);
          }
        },
        (error) => {
          console.error('Error in payments snapshot listener:', error);
        }
      );
    };

    fetchPaymentsData();

    const authListener = auth.onAuthStateChanged((user) => {
      if (!user && listener) {
        listener();
      }
    });

    return () => {
      if (listener) listener();
      authListener();
    };
  }, [userContext, perPage, selectedCompany]);

  useEffect(() => {
    // structurePaymentsData();
    fetchCompanies()
      .then((cmplist) => {
        let options = [
          { label: 'All', value: 0 },
          { label: 'Trippie Air', value: 'null' },
          { label: 'Logistics Companies', value: 'logistics' },
        ];
        cmplist.forEach((dc) => {
          const obj = {};
          if (haveValue(dc)) {
            obj.label = dc.data.companyName;
            obj.value = dc.id;
          }
          options.push(obj);
        });
        console.log('options', options);
        selectedCompanyOptions(options);
      })
      .catch((err) => console.error('Error while fetch Companies', err));
  }, [paymentData, offset, perPage, selectedCompany]);

  const handleSelect = (select) => {
    setSelectedCompany(select);
  };

  const StructurePaymentsData = () => {
    const slice = paymentData.slice(offset, offset + perPage);

    const amount = (rowData) => {
      return (
        <React.Fragment> {formatCurrency(rowData?.data.amountCents / 100)} </React.Fragment>
      );
    };
    const paymentStatus = (rowData) => {
      return (
        <React.Fragment>
          {' '}
          {rowData?.data.paymentSuccess ? 'Success' : 'Failed'}{' '}
        </React.Fragment>
      );
    };
    const receiverInfo = (rowData) => {
      return (
        <React.Fragment>
          {' '}
          {rowData?.data?.companyInfo
            ? rowData.data.companyInfo.companyName
            : 'Trippie Air'}{' '}
        </React.Fragment>
      );
    };

    const ItemName = (rowData) => {
      return <React.Fragment>{rowData?.data?.itemName}</React.Fragment>;
    };

    const lastUpdate = (rowData) => {
      return (
        <React.Fragment>
          {' '}
          {rowData?.data?.lastUpdated.toDate().toLocaleString('en-NZ')}{' '}
        </React.Fragment>
      );
    };

    const receiptUrl = (rowData) => {
      return (
        <React.Fragment>
          {' '}
          {rowData?.data?.receiptUrl ? (
            <a className="receipt-link" href={rowData.data.receiptUrl}>
              Receipt
            </a>
          ) : (
            '-'
          )}{' '}
        </React.Fragment>
      );
    };
    return (
      <DataTable value={slice} stripedRows tableStyle={{ minWidth: '50rem' }}>
        <Column field="id" header="Delivery Id"></Column>
        <Column field="itemName" body={ItemName} header="Item Name"></Column>
        <Column field="amountCents" body={amount} header="Paid"></Column>
        <Column
          field="paymentSuccess"
          body={paymentStatus}
          header="Status"
        ></Column>
        <Column
          field="companyInfo.companyName"
          body={receiverInfo}
          header="Receiver"
        ></Column>
        <Column
          field="lastUpdated"
          body={lastUpdate}
          header="Last Update"
        ></Column>
        <Column field="receiptUrl" body={receiptUrl} header="Receipt"></Column>
      </DataTable>
    );

    // setPostData(postData);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
  };

  console.log('isLoading', isLoading);

  return (
    <div style={{ padding: '30px' }}>
      <Helmet>
        <title>Trippie Air | Payments</title>
      </Helmet>
      <div>
        <div className="assign-drive">
          <h1 className="control-title">Payments</h1>
          {!userContext.isThirdParty && (
            <Select
              isSearchable={true}
              isClearable={true}
              defaultValue={selectedCompany}
              onChange={handleSelect}
              options={companyOptions}
            />
          )}
        </div>

        <div className="notifications-content">
          <div className="notifications-list-table">
            {isLoading ? (
              <Loader show={isLoading ? 'show' : null} />
            ) : (
              <StructurePaymentsData />
            )}
          </div>
          {!isLoading && (
            <div className="pagination-container no-shadow">
              <ReactPaginate
                previousLabel={<i className="fas fa-arrow-left"></i>}
                nextLabel={<i className="fas fa-arrow-right"></i>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Earnings;
