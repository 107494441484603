import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Ratings from "react-ratings-declarative";
import { Helmet } from "react-helmet";

import {
  handleBanUserModal,
  handleOnlineUserModal,
  spinner,
  loadingSpinner,
  showConfirmDialog,
  calculateRating,
} from "../../App";
import { auth, functions, firestore } from "../../Firebase";

import RedCross from "../../img/redcross.png";
import GreenCheck from "../../img/greencheck.png";
import Pending from "../../img/pending.png";

import BanUser from "../ModalComponents/BanUser";
import OnlineUser from "../ModalComponents/OnlineUser";
import Spinner from "../ModalComponents/Spinner";
import LoadingSpinner from "../ModalComponents/LoadingSpinner";
import Recharge from "../Models/Recharge";
import { formatDate, getStatus } from "../../utils/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { fetchCompanyById } from "../../services/user-services";
import DriverProfile from "../Models/DriverProfileUpdate";
import { UserContext } from "../../Providers/UserProvider";

const CustomerProfile = ({ match }) => {
  const { isThirdParty } = useContext(UserContext);

  const { userId } = useParams();
  const [isBanned, setIsBanned] = useState(null);
  const [isOnline, setIsOnline] = useState(null);
  const [userData, setUserData] = useState(null);
  const [requestsSent, setRequestsSent] = useState(null);
  const [requestsReceived, setRequestsReceived] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDriverUpdate, setOpenDriverUpdate] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    getAllUserDetails(userId);
    checkBan(userId);
    auth.onAuthStateChanged((user) => {
      if (!user) {
        // cleanup listeners
      }
    });
  }, [userId, openDriverUpdate, open]);

  useEffect(() => {
    if (userData?.data?.isTraveller) {
      fetchRequestsReceived(userId);
    } else if (!userData?.data?.isTraveller) {
      fetchRequestsSent(userId);
    }
  }, [userId, userData?.data?.isTraveller]);

  const getAllUserDetails = async (userId) => {
    loadingSpinner(true);
    const getAllUserDetails = functions.httpsCallable("getAllUserData");
    let data = await getAllUserDetails({ userId });
    console.log(data);
    checkOnline(data.data.public.online);
    if (data.data.isTraveller) {
      const company = await fetchCompanyById(data.data.private.companyId);
      data.data.traveller.companyId = company;
    }

    setUserData(data);
  };

  const fetchRequestsSent = (userId) => {
    return firestore
      .collectionGroup("requests_stored")
      .where("senderId", "==", userId)
      .orderBy("creationDate", "desc")
      .onSnapshot((querySelector) => {
        const currentRequestsList = [];
        querySelector.forEach((doc) => {
          currentRequestsList.push({
            data: doc.data(),
            id: doc.id,
          });
        });
        setRequestsSent(currentRequestsList);
      });
  };

  function fetchRequestsReceived(userId) {
    firestore
      .collection(`delivery_requests/${userId}/requests_received`)
      .orderBy("lastUpdated", "desc")
      .onSnapshot((querySelector) => {
        const currentRequestsList = [];
        querySelector.forEach((doc) => {
          currentRequestsList.push({
            data: doc.data(),
            id: doc.id,
          });
        });
        setRequestsReceived(currentRequestsList);
      });
  }

  const checkBan = (userId) => {
    const listenerThree = firestore
      .collection("banned_users")
      .doc(userId)
      .onSnapshot((docSnap) => {
        setIsBanned(docSnap.exists);
      });
  };

  const checkOnline = (online) => {
    setIsOnline(online);
  };

  const handleOnlineButton = () => {
    const { isThirdPartyLogisticDriver, traveller } = userData.data;
    const company = traveller?.companyId;

    // Check if the user is a driver and belongs to a logistic company
    if (isThirdPartyLogisticDriver && traveller) {
      if (company.verified !== 1) {
        alert("The Logistic Company is not verified.");
        return;
      }

      if (company.isBanned) {
        alert(
          "The Logistic Company is banned. You cannot ban or set online drivers until the company is unbanned."
        );

        return;
      }
    }

    if (isOnline === null) {
      document.getElementById("onlineButton").innerHTML = "Loading...";
      document.getElementById("onlineButton").classList.add("banned");
    } else if (!isOnline) {
      handleOnlineUserModal(true);
    } else {
      handleOnlineUserModal(true);
    }
  };

  const handleBanButton = () => {
    const { isThirdPartyLogisticDriver, traveller } = userData.data;
    const company = traveller?.companyId;

    // Check if the user is a driver and belongs to a logistic company
    if (isThirdPartyLogisticDriver && traveller) {
      if (company.verified !== 1) {
        alert("The Logistic Company is not verified.");
        return;
      }

      if (company.isBanned) {
        alert(
          "The Logistic Company is banned. You cannot ban or unban drivers until the company is unbanned."
        );

        return;
      }
    }

    if (isBanned === null) {
      document.getElementById("banButton").innerHTML = "Loading...";
      document.getElementById("banButton").classList.add("banned");
    } else if (!isBanned) {
      handleBanUserModal(true);
    } else {
      unban();
    }
  };

  const unban = () => {
    const enableUserAccount = functions.httpsCallable("enableUserAccount");
    const data = {
      userId,
    };
    spinner(true);
    enableUserAccount(data)
      .then(() => {
        showConfirmDialog(false, "The enable hammer has spoken.");
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  };

  const trippieButtons = () => {
    if (isBanned === null) {
      return (
        <div
          id="banButton"
          onClick={() => handleBanUserModal(true)}
          className="user-action-button ban-user-btn noselect banned"
        >
          Loading...
        </div>
      );
    } else if (!isBanned) {
      return (
        <div
          id="banButton"
          onClick={handleBanButton}
          className="user-action-button ban-user-btn noselect"
        >
          Ban
        </div>
      );
    } else {
      return (
        <div
          id="banButton"
          onClick={handleBanButton}
          className="user-action-button ban-user-btn noselect"
        >
          Unban
        </div>
      );
    }
  };

  const onlineButtons = () => {
    if (isOnline === null) {
      return (
        <div
          id="onlineButton"
          onClick={() => handleOnlineUserModal(true)}
          className="user-action-button ban-user-btn noselect banned"
        >
          Loading...
        </div>
      );
    } else if (!isOnline) {
      return (
        <div
          id="onlineButton"
          onClick={handleOnlineButton}
          className="user-action-button ban-user-btn noselect"
        >
          Set Online
        </div>
      );
    } else {
      return (
        <div
          id="onlineButton"
          onClick={handleOnlineButton}
          className="user-action-button ban-user-btn noselect"
        >
          Set Offline
        </div>
      );
    }
  };

  const rechargeButton = () => {
    if (!userData.data.traveller) {
      return (
        <div
          // id="banButton"
          onClick={() => setOpen(true)}
          className="user-action-button"
        >
          Recharge
        </div>
      );
    }
  };

  const displayTravellerDetails = () => {
    let transferStatus = RedCross;
    if (!userData || !userData.data.isTraveller) {
      return <></>;
    } else {
      let status = userData.data.traveller?.transfersStatus;
      switch (status) {
        case "active":
          transferStatus = GreenCheck;
          break;
        case "pending":
          transferStatus = Pending;
          break;
        case "inactive":
          transferStatus = RedCross;
          break;
        default:
      }

      return (
        <div className="customer-info traveller-details">
          <div className="customer-info-title">Driver Details</div>
          <div className="traveller-data">
            <div className="account-status">
              <div>
                <img
                  className="drop-off-img"
                  src={
                    userData.data.traveller?.detailsSubmitted
                      ? GreenCheck
                      : RedCross
                  }
                  alt={
                    userData.data.traveller?.detailsSubmitted
                      ? "Check"
                      : "Cross"
                  }
                />
              </div>
              <div>Account Complete</div>
            </div>
            <div className="transfer-status">
              <div>
                <img
                  className="drop-off-img"
                  src={transferStatus}
                  alt={"Cross"}
                />
              </div>
              <div>Transfers Status</div>
            </div>
            <div className="payouts-status">
              <div>
                <img
                  className="drop-off-img"
                  src={
                    userData.data.traveller?.payoutsEnabled
                      ? GreenCheck
                      : RedCross
                  }
                  alt={
                    userData.data.traveller?.payoutsEnabled ? "Check" : "Cross"
                  }
                />
              </div>
              <div>Payouts Enabled</div>
            </div>
          </div>
          <div className="trip-list">
            <div className="trip-list-table">
              <div className="trip-list-table-head">
                <div className="trip-list-table-head-title">Join Date</div>
                <div className="trip-list-table-head-title">License No.</div>
                <div className="trip-list-table-head-title">Type</div>
                <div className="trip-list-table-head-title">
                  License Exp. Date
                </div>

                <div className="trip-list-table-head-title">
                  Logistic Company
                </div>
                <div className="trip-list-table-head-title">Id No.</div>
                <div className="trip-list-table-head-title">
                  Id Expiration Date
                </div>
                <div className="trip-list-table-head-title">Action</div>
              </div>
              <div className="trip-list-table-body">
                {/* Driver data information */}
                <div
                  className="trip-list-table-item"
                  style={{ display: "flex" }}
                >
                  <div className="trip-list-item-entry">
                    {formatDate(userData?.data?.traveller?.dateOfJoining)}
                  </div>
                  <div className="trip-list-item-entry">
                    {userData?.data?.traveller?.licenseNo}
                  </div>
                  <div className="trip-list-item-entry">
                    {" "}
                    {userData?.data?.traveller?.type}
                  </div>
                  <div className="trip-list-item-entry">
                    {" "}
                    {userData?.data?.traveller?.licenseExp}
                  </div>
                  <div className="trip-list-item-entry">
                    {userData?.data?.traveller?.companyId === "null"
                      ? "Tripee Air"
                      : userData?.data?.traveller?.companyId?.companyName}
                  </div>
                  <div className="trip-list-item-entry">
                    {userData.data.traveller?.accountId}
                  </div>

                  <div className="trip-list-item-entry">
                    {userData.data.traveller?.idExpiration}
                  </div>
                  <div className="trip-list-item-entry">
                    <div
                      className="user-action-button save-user-btn noselect"
                      onClick={() => setOpenDriverUpdate(true)}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  function handleChange(event) {
    const { name, value } = event.target;
    console.log({ name, value }, "--------name");

    setUserData((prev) => {
      let updatedData = { ...prev };

      if (name === "displayName" || name === "aboutMe") {
        updatedData.data.public = {
          ...prev.data.public,
          [name]: value,
        };
      } else if (["street", "postcode", "suburb", "city"].includes(name)) {
        updatedData.data.private = {
          ...prev.data.private,
          addressModel: {
            ...prev.data.private.addressModel,
            [name]: value,
          },
        };
      } else {
        updatedData.data.private = {
          ...prev.data.private,
          [name]: value,
        };
      }

      return updatedData;
    });
  }

  const saveUserInfo = () => {
    if (!userData) {
      return;
    } else {
      spinner(true);
      const update = functions.httpsCallable("updateUserProfileInfo");
      update({
        public: userData.data.public,
        private: userData.data.private,
        userId,
      })
        .then((result) => {
          showConfirmDialog(false, "Profile updated successfully");
        })
        .catch((err) => {
          console.error(err, "err while update");
          showConfirmDialog(true, err.message);
        });
      // updateUserProfile(userData);
    }
  };

  const displayUserDetails = () => {
    if (!userData) {
      return (
        <div className="user-data">
          <LoadingSpinner />
        </div>
      );
    } else {
      return (
        <div className="user-data">
          <div className="user-data-container">
            <div className="details-img">
              {userData.data.public.profileImgUrl ? (
                <img
                  className="user-profile-image large-profile"
                  src={userData.data.public?.profileImgUrl}
                  alt="user-profile"
                />
              ) : (
                <i className="far fa-user-circle"></i>
              )}
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                First Name:
              </div>
              <div className="user-data-content-info">
                <input
                  className="user-data-content-info-input"
                  name="firstName"
                  value={userData.data.private?.firstName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Last Name:
              </div>
              <div className="user-data-content-info">
                <input
                  className="user-data-content-info-input"
                  name="surname"
                  value={userData.data.private?.surname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Email:(Not Editable)
              </div>
              <div className="user-data-content-info ">
                <input
                  className="user-data-content-info-input not-editable"
                  name="email"
                  value={userData.data.private?.email}
                  readOnly
                  // onChange={}
                />
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Phone Number:
              </div>
              <div className="user-data-content-info">
                <input
                  className="user-data-content-info-input"
                  name="phone"
                  value={userData.data.private?.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <br />
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">Display:</div>
              <div className="user-data-content-info">
                <input
                  className="user-data-content-info-input"
                  name="displayName"
                  value={userData.data.public?.displayName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">About Me:</div>
              <div className="user-data-content-info">
                <textarea
                  id="aboutMeTextArea"
                  className="user-data-content-info-input"
                  name="aboutMe"
                  value={userData.data.public?.aboutMe}
                  onChange={handleChange}
                />
              </div>
            </div>
            {userData.data.isTraveller && (
              <div className="user-data-content ">
                <div className="user-data-content-info bold-title">
                  Rating:(Not Editable)
                </div>
                <div className="user-data-content-info">
                  <div className="user-data-content-info-input not-editable">
                    <Ratings
                      rating={calculateRating(
                        userData.data.public?.totalRating,
                        userData.data.public?.numberOfRatings
                      )}
                      widgetRatedColors="rgb(0, 72, 108)"
                      widgetDimensions="16px"
                      widgetSpacings="2px"
                    >
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                    </Ratings>
                    <span style={{ paddingLeft: "5px" }}>
                      ({userData.data.public?.numberOfRatings})
                    </span>
                  </div>
                </div>
              </div>
            )}

            <br />
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">Address:</div>
              <div className="user-data-content-info">
                <div className="addressFields">
                  <input
                    className="user-data-content-info-input"
                    name="street"
                    value={userData.data.private?.addressModel?.street}
                    onChange={handleChange}
                  />
                </div>
                <div className="addressFields">
                  <input
                    className="user-data-content-info-input"
                    placeholder="Suburb"
                    name="suburb"
                    value={userData.data.private.addressModel?.suburb}
                    onChange={handleChange}
                  />
                </div>
                <div className="addressFields">
                  <input
                    className="user-data-content-info-input"
                    name="city"
                    value={userData.data.private?.addressModel?.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="addressFields">
                  <input
                    className="user-data-content-info-input"
                    name="postcode"
                    value={userData.data.private?.addressModel?.postcode}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {!userData.data.isTraveller && (
              <div className="user-data-content">
                <div className="user-data-content-info bold-title">
                  Wallet Balance
                </div>
                <div className="user-data-content-info">
                  <input
                    id="aboutMeTextArea"
                    className="user-data-content-info-input"
                    name="aboutMe"
                    value={userData.data.private.wallet}
                    readOnly
                  />
                </div>
              </div>
            )}

            <div className="action-buttons-container">
              {rechargeButton()}
              {onlineButtons()}
              {trippieButtons()}
              <div
                className="user-action-button save-user-btn noselect"
                onClick={saveUserInfo}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const displayCurrentSentRequests = () => {
    if (!userData) {
      return <div className="user-data"></div>;
    } else {
      return (
        <DataTable
          value={requestsSent}
          stripedRows
          tableStyle={{ minWidth: "15rem" }}
        >
          <Column
            field="id"
            header="Request Id"
            body={(rowData) => (
              <Link
                to={{
                  pathname: `/Delivery/${rowData.id}/${rowData.data.senderId}`,
                }}
                className="request-list-link"
              >
                {rowData.id}{" "}
              </Link>
            )}
          ></Column>
          <Column
            field="data.deliveryRequest.itemName"
            header="Item Name"
            body={(rowData) => rowData.data.itemName}
          ></Column>
          <Column
            field="data.deliveryRequest.itemName"
            header="Payment Type"
            body={(rowData) => rowData.data.paymentType?.toUpperCase()}
          ></Column>
          <Column
            field="data.deliveryRequest.itemName"
            header="Payment Status"
            body={(rowData) =>
              rowData.data.paymentSuccess ? "Done" : "Pending"
            }
          ></Column>
          <Column
            field="data.deliveryRequest.status"
            header="Status"
            body={(rowData) => getStatus(rowData.data.status)}
          ></Column>
        </DataTable>
      );
    }
  };

  const displayCurrentReceivedRequests = () => {
    if (!userData) {
      return <div className="user-data"></div>;
    } else {
      return (
        <DataTable
          value={requestsReceived}
          stripedRows
          tableStyle={{ minWidth: "15rem" }}
        >
          <Column
            field="id"
            header="Request Id"
            body={(rowData) => (
              <Link
                to={{
                  pathname: `/Delivery/${rowData.id.split("_")[0]}/${
                    rowData.data?.deliveryRequest?.senderId
                  }`,
                }}
                className="request-list-link"
              >
                {rowData.id.split("_")[0]}{" "}
              </Link>
            )}
          ></Column>
          <Column
            field="data.deliveryRequest.itemName"
            header="Item Name"
            body={(rowData) => rowData.data.deliveryRequest?.itemName}
          ></Column>
          <Column
            field="data.deliveryRequest.status"
            header="Status"
            body={(rowData) => getStatus(rowData.data.deliveryRequest?.status)}
          ></Column>
        </DataTable>
      );
    }
  };
  function openListDropdown(dropdownMenuId, dropdownId, chevronId) {
    let dropdownMenu = document.getElementById(dropdownMenuId);
    let dropdown = document.getElementById(dropdownId);
    let chevron = document.getElementById(chevronId);
    if (dropdown.classList.contains("request-list-open")) {
      dropdown.classList.remove("request-list-open");
      dropdownMenu.classList.remove("active");
    } else {
      dropdown.classList.add("request-list-open");
      dropdownMenu.classList.add("active");
    }
  }

  // console.log('user data+++++++++++', userData);
  return (
    <div style={{ padding: "30px" }}>
      <BanUser />
      <OnlineUser />
      <Spinner />
      <Helmet>
        <title>
          User Profile |{" "}
          {userData ? userData?.data?.public?.displayName : "Loading..."}
        </title>
        <meta name="description" content="Trippie Air | Authorise new trips" />
      </Helmet>
      <div>
        <h1 className="control-title">
          {userData
            ? `${userData.data.public?.displayName}'s Profile`
            : "Loading..."}
        </h1>
        <div className="customer-data-container" style={{ display: "flex" }}>
          <div className="customer-data-container-50 left-50-container">
            <div className="display-customer-details">
              <div className="customer-info">
                <div className="customer-info-title">
                  {userData?.data?.isTraveller ? "Driver" : "Customer"} Details
                </div>
                {displayUserDetails()}
              </div>
            </div>
          </div>
          <div className="customer-data-container-50">
            <div className=" display-customer-details">
              <div className="customer-info">
                <div className="customer-info-title">Current Requests</div>
                {userData && userData?.data?.isTraveller === false && (
                  <div className="request-list">
                    {/* Requests Received */}
                    <div
                      id="requestsSentBtn"
                      onClick={() =>
                        openListDropdown(
                          "requestsSentBtn",
                          "requestsSentList",
                          "sentRequestChevron"
                        )
                      }
                      className="change-trip-display-button noselect"
                    >
                      Sent Requests
                      <span className="request-chevron">
                        <i
                          id="sentRequestChevron"
                          className="fas fa-chevron-down"
                        ></i>
                      </span>
                    </div>
                    {/* Requests sent */}

                    <div
                      id="requestsSentList"
                      className="request-list-dropdown "
                    >
                      {displayCurrentSentRequests()}
                    </div>
                  </div>
                )}

                {userData && userData.data.isTraveller && (
                  <div className="request-list">
                    <div
                      id="requestsRecievedBtn"
                      onClick={() =>
                        openListDropdown(
                          "requestsRecievedBtn",
                          "requestsRecievedList",
                          "receivedRequestChevron"
                        )
                      }
                      className="change-trip-display-button noselect"
                    >
                      Received Requests
                      <span className="request-chevron">
                        <i
                          id="receivedRequestChevron"
                          className="fas fa-chevron-down"
                        ></i>
                      </span>
                    </div>
                    <div
                      id="requestsRecievedList"
                      className="request-list-dropdown "
                    >
                      {displayCurrentReceivedRequests()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {displayTravellerDetails()}
      </div>

      <Recharge
        open={open}
        onClose={() => {
          setOpen(false);
          window.location.reload();
        }}
        userId={userId}
      />
      <DriverProfile
        openModel={openDriverUpdate}
        setOpenModel={setOpenDriverUpdate}
        userId={userId}
        data={userData?.data?.traveller}
      />
    </div>
  );
};

export default CustomerProfile;
