import React, { Component } from 'react';

import { firestore } from '../../Firebase';
import { spinner, showConfirmDialog } from "../../App"

import Spinner from '../ModalComponents/Spinner'

export default class OtherSettings extends Component{

  constructor(props){
    super(props);
    this.state = {
      terms: '',
      privacy: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchLegalLinks(){
    firestore.collection('legal').doc('links').get().then((doc) => {
      if(doc.exists){
        this.setState({
          terms: doc.data().terms,
          privacy: doc.data().privacy
        });
      } else {
        spinner(true);
        showConfirmDialog(true, "There was an issue retrieving data. Please contact your administrator!");
      }
    });
  }

  validate(){

  }

  save(){
    if(!this.validate()) return;
    let updateData = {
      terms: this.state.terms,
      privacy: this.state.privacy
    }

    firestore.collection('legal').doc("links").update(updateData).then((error) => {
      if(!error){
        spinner(true);
        showConfirmDialog(false, "Changes succesfully written to database!");
      }else{
        spinner(true);
        showConfirmDialog(true, `Changes failed: ${error}`);
      }
    });
  }

  handleInputChange(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  componentDidMount(){
    this.fetchLegalLinks();
  }

  render(){
    return(
      <div style={{padding: "30px"}}>
        <Spinner/>
        <div>
          <h1 className="control-title">
            Legal Links
          </h1>
          <div className="edit-faq-container">
            <div className="edit-legal-desc">
              <h3>Warning:</h3>
              <p>
                Editing the following links will change the terms and conditions and privacy policies documents that are
                displayed to the user within both the Trippie Air applications and information websites.
              </p>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Terms and Conditions Link
              </div>
              <div className="user-data-content-info">
                <input
                className="user-data-content-info-input"
                name="terms"
                value={this.state.terms}
                onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Privacy Policies Link:
              </div>
              <div className="user-data-content-info">
                <input
                className="user-data-content-info-input"
                name="privacy"
                value={this.state.privacy}
                onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="action-buttons-container align-bottom">
              <div onClick={() => this.save()} className="user-action-button save-user-btn noselect">Save</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
