import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { UserContext } from '../../Providers/UserProvider';
import { Button, Form, Spinner } from 'react-bootstrap';
import { firestore, functions } from '../../Firebase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { showConfirmDialog, spinner } from '../../App';

export default function UserProfile() {
  const { user, isThirdParty, admin } = useContext(UserContext);
  console.log('user profile', { user, isThirdParty, admin });
  const [LogisticInfo, setLogisticInfo] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [isSubmittingReset, setIsSubmittingReset] = useState(false);
  const [isFormSubmit, setFormSubmit] = useState(false);

  function fetch() {
    firestore
      .collection('companies')
      .where('contactPersonEmail', '==', user.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setLogisticInfo(doc.data());
          setCompanyId(doc.id);
        });
      });
  }

  // Validation schema using yup
  const schema = yup.object().shape({
    address: yup.string().required('Address 2  is required').min(5),
    street: yup.string().required('Address 1 is required').min(5),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(
        /^[0-9]{7,14}$/,
        'Phone number must be between 7 and 14 digits and contain only numbers'
      ),

    contactPersonName: yup
      .string()
      .required('Contact person name is required')
      .min(3)
      .matches(
        /^[A-Za-z\s]+$/,
        'Contact person name must only contain alphabetic characters'
      ),

    contactPersonJobTitle: yup
      .string()
      .required('Job title is required')
      .min(3)
      .matches(
        /^[A-Za-z\s]+$/,
        'Contact person name must only contain alphabetic characters'
      ),
    contactPersonPhoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(
        /^[0-9]{7,14}$/,
        'Phone number must be between 7 and 14 digits and contain only numbers'
      ),

    contactPersonEmail: yup
      .string()
      .email('Invalid email')
      .required('Email is required'),
  });

  const {
    register,
    handleSubmit,

    getValues,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      street: '',
      address: '',
      phoneNumber: '',
      contactPersonName: '',
      contactPersonJobTitle: '',
      contactPersonPhoneNumber: '',
      contactPersonEmail: '',
    },
  });

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (LogisticInfo) {
      reset({
        ...LogisticInfo,
        companyId: companyId,
      });
    }
  }, [LogisticInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    setFormSubmit(true);
    const updateDataCompanyProfile = functions.httpsCallable(
      'updateCompanyProfile'
    );
    updateDataCompanyProfile(data)
      .then((res) => {
        console.log('res', res);
        alert('Profile updated successfully');
      })
      .catch((error) => {
        console.log('error', error);
        alert(error.message);
      })
      .finally(() => {
        setFormSubmit(false);
      });
  };

  const resetPasswordHandler = async () => {
    const resetPassword = functions.httpsCallable('resetPassword');
    spinner(true);
    setIsSubmittingReset(true);
    resetPassword({ email: user.email })
      .then((res) => {
        alert('Password reset link has been sent to your email');
      })
      .catch((error) => {
        let errorMessage = 'An error occurred. Please try again later.';

        if (error.message.includes('RESET_PASSWORD_EXCEED_LIMIT')) {
          errorMessage =
            'Password reset limit exceeded. Please try again after 24 hours.';
        } else if (error.code) {
          // Handle other specific error codes if needed
          switch (error.code) {
            case 'auth/user-not-found':
              errorMessage = 'No user found with this email address.';
              break;
            case 'auth/invalid-email':
              errorMessage = 'Invalid email address.';
              break;
            default:
              errorMessage = 'An error occurred. Please try again later.';
              break;
            // Add more cases as needed
          }
        }

        // Display the error message
        alert(errorMessage);
      })
      .finally(() => {
        setIsSubmittingReset(false);
      });
  };
  const onError = (error) => console.log(error);

  console.log('errors', errors);
  return (
    <div style={{ padding: '30px' }}>
      <Helmet>
        <title>{`${user.displayName} | Profile`}</title>
        <meta name="description" content="Trippie Air | User Profiles" />
      </Helmet>
      <div>
        <h1 className="control-title">Profile</h1>
        <div className="profile-content">
          <div>{user.displayName}</div>
        </div>
      </div>
      {isThirdParty && (
        <div className="data-container">
          <div className="box-title-container">
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}></div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="trip-data" style={{ maxWidth: '100%' }}>
              <div id="tripFlightDetails">
                <div className="depart-arrive-details">
                  <div className="depart-details">
                    <div className="info-company">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Address 1</Form.Label>
                        <Form.Control
                          type="textarea"
                          placeholder="Address 1"
                          {...register('street')}
                          name="street"
                        />
                        {errors?.street && (
                          <p className="error">{errors?.street?.message}</p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Address 2</Form.Label>
                        <Form.Control
                          type="textarea"
                          placeholder="Address 2"
                          {...register('address')}
                          name="address"
                        />
                        {errors?.address && (
                          <p className="error">{errors?.address?.message}</p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Company Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          {...register('phoneNumber')}
                          name="phoneNumber"
                        />
                        {errors?.phoneNumber && (
                          <p className="error">
                            {errors?.phoneNumber?.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Contact Person Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact Person Name"
                          {...register('contactPersonName')}
                          name="contactPersonName"
                        />
                        {errors?.contactPersonName && (
                          <p className="error">
                            {errors?.contactPersonName?.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact Person Job Title"
                          {...register('contactPersonJobTitle')}
                          name="contactPersonJobTitle"
                        />
                        {errors?.contactPersonJobTitle && (
                          <p className="error">
                            {errors?.contactPersonJobTitle?.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact Person Phone Number"
                          {...register('contactPersonPhoneNumber')}
                          name="contactPersonPhoneNumber"
                        />
                        {errors?.contactPersonPhoneNumber && (
                          <p className="error">
                            {errors?.contactPersonPhoneNumber?.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Contact Person Email"
                          {...register('contactPersonEmail')}
                          name="contactPersonEmail"
                        />
                        {errors?.contactPersonEmail && (
                          <p className="error">
                            {errors?.contactPersonEmail?.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Company Code</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          {...register('companyCode')}
                          name="companyCode"
                        />
                        <span class="no-edit">not editable</span>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> </Form.Label>
                        <Form.Label>Commercial License </Form.Label>

                        {getValues('commercialLicenseUrl') ? (
                          <a
                            href={getValues('commercialLicenseUrl')}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: '#fff',
                              textDecoration: 'none',
                            }}
                          download
                          >
                            <button
                              className="user-action-button save-user-btn noselect"
                              style={{
                                display: 'block',
                                backgroundColor: '#343a40',
                                padding: '5px 10px',
                                borderRadius: '5px',
                              }}
                              type="button"
                            >
                              Download
                            </button>
                          </a>
                        ) : (
                          <Form.Control
                            type="file"
                            readOnly
                            placeholder="address"
                            name="commercialLicenseUrl"
                          />
                        )}
                      </Form.Group>

                      <div className="d-flex gap-2 justify-content-end">
                        <Button
                          className="btn btn-primary h-10 px-4 py-2"
                          onClick={resetPasswordHandler}
                          disabled={isSubmittingReset}
                        >
                          {isSubmittingReset ? 'Loading...' : 'Reset Password'}
                        </Button>
                        {console.log({
                          isValid,
                          isSubmitting,
                        })}
                        <Button
                          className="btn btn-primary  px-4 py-2"
                          type="submit"
                          disabled={isSubmitting || isFormSubmit}
                        >
                          {isFormSubmit ? 'Submitting' : 'Submit'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}
