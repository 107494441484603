import React, { useContext } from 'react';
import '../App.css';
import { UserContext } from '../Providers/UserProvider';
import Login from './Login';
import Dashboard from './Dashboard';
import ActiveCompanyAccount from './DashboardComponents/ActiveCompnayAccount';

function Application() {
  const { user, isDisable } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('code') && urlParams.has('id')) {
    return <ActiveCompanyAccount />;
  }

  console.log('application disable', isDisable);

  if (isDisable) {
    return <Login />;
  }

  return user ? <Dashboard /> : <Login />;
}

export default Application;
