import React from 'react';

import GreenCheck from '../../img/greencheck.png';
import { spinner } from '../../App'

export default function Spinner(){
  return(
    <div>
      <div id="loadSpinner" className="spinner-background">
        <div className="this-that">
          <div className="spinner loading">
          </div>
        </div>
        <div id="modalPopup" className="modal-popup closed">
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{width: '40%'}}>
              <img
                id="popupImg"
                style={{width:"100%"}}
                src={GreenCheck}
                alt="Check"
              />
            </div>
            <div style={{width: '50%'}}>
              <h4 id="popupHeader">Title Filler</h4>
              <p></p>
              <p id="popupMessage"></p>
              <div style={{width:'100%', textAlign: 'center', marginTop: '20px'}}>
                <button type="button" className="btn btn-info" onClick={() => spinner(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
