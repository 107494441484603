import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Chart from 'chart.js/auto'

export default class Analytics extends Component {

  displayTestChart(){
    const chartCtx = document.getElementById('testChart');

    let lineChart = new Chart(chartCtx, {
      type: "bar",
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: 'Test Data',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1,
        }]
      }
    });

  }

  componentDidMount(){
    this.displayTestChart();
  }

  render(){
    return(
      <div>
        <Helmet>
          <title>Trippie Air | Analytics Dashboard</title>
          <meta name="description" content="Trippie Air | Analytics Dashboard" />
        </Helmet>
        <div style={{padding: '30px'}}>
          <h1 className="control-title">
            Dashboard
          </h1>
          <div className="user-profile-list">
            <h1 className="control-title">
            </h1>
            <div className='chart-container' style={{position : 'relative', height:"30vh", width:"30vw"}}>
              <canvas id="testChart"></canvas>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
