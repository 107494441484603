import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Avatar,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Grid,
} from "@mui/material";
import {
  Edit,
  Delete,
  ArrowBack,
  Refresh,
  LockOpen,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { firestore, functions } from "../../Firebase";

const ViewAdminUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [activateLoading, setActivateLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await firestore
          .collection("user_admin")
          .doc(userId)
          .get();
        if (userDoc.exists) {
          setUserData(userDoc.data());
          console.log(userDoc.data());
        } else {
          setError("User not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  const toggleActivate = async () => {
    try {
      setActivateLoading(true);
      const functionName =
        userData.active === false ? "activateAdminUser" : "deactivateAdminUser";
      await functions.httpsCallable(functionName)({ userId });
      navigate(-1);
    } catch (err) {
      console.error(err);
    } finally {
      setActivateLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: "auto" }}>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
        sx={{ mb: 3 }}
      >
        Back
      </Button>

      <Card sx={{ p: 4 }}>
        <Box display="flex" alignItems="center" mb={3}>
          <Avatar
            sx={{ width: 80, height: 80, mr: 2 }}
            src={userData?.photoURL}
          >
            {userData?.name?.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="h5">{userData?.displayName}</Typography>
            <Typography color="textSecondary">{userData?.email}</Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              First Name
            </Typography>
            <Typography variant="body1">
              {userData?.firstName || "Not provided"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Last Name
            </Typography>
            <Typography variant="body1">
              {userData?.lastName || "Not provided"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Display Name
            </Typography>
            <Typography variant="body1">
              {userData?.displayName || "Not provided"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Email
            </Typography>
            <Typography variant="body1">
              {userData?.email || "Not provided"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Phone
            </Typography>
            <Typography variant="body1">
              {userData?.phone || "Not provided"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              About
            </Typography>
            <Typography variant="body1">
              {userData?.aboutMe || "No description provided"}
            </Typography>
          </Grid>
        </Grid>

        <Box display="flex" gap={2} mt={4}>
          <Button
            variant="contained"
            disabled={activateLoading}
            startIcon={<LockOpen />}
            onClick={() => toggleActivate()}
          >
            {activateLoading && <CircularProgress size={24} />}
            {userData?.active === false ? "Activate" : "Deactivate"}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ViewAdminUser;
