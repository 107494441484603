import React, { useState, useEffect } from "react";
import { firestore, functions } from "../../Firebase";
import Loader from "../Loader";
import Error from "../Error";
import { showConfirmDialog, spinner } from "../../App";

const ActiveCompanyAccount = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [companyEmail, setCompanyEmail] = useState("");
  const [error, setError] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const id = urlParams.get("id");

  async function sendActiveCompanyEmail() {
    await functions.httpsCallable("activeAccountConfirmation")({
      companyId: id,
    }).then(() => {
      console.log("Email sent successfully");
    }).catch((error) => {
      showConfirmDialog(true, error.message);
    });
  }

  async function verifyCompany() {
    if (!code || !id) {
      setError("Invalid URL parameters");
      return;
    }

    try {
      const companySnapshot = await firestore
        .collection("companies")
        .doc(id)
        .get();

      if (companySnapshot.exists && companySnapshot.data().companyCode === code) {
        await sendActiveCompanyEmail();
        setIsVerified(true);
        setCompanyEmail(companySnapshot.data().contactPersonEmail || "");
      } else {
        setError("Company not found or invalid code");
      }
    } catch (err) {
      console.error("Error verifying company:", err);
      setError("An error occurred while verifying the company");
    }
  }

  useEffect(() => {
    verifyCompany();
  }, [code, id]);

  const loading = !(isVerified || error);

  if (loading) {
    return <Loader show="show" />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return (
    <div className="verified-container">
      <div className="verified-icon">✅</div>
      <h2 className="verified-title">Your Company Account is Activated</h2>
      <p className="verified-message">
        You will receive an email at{" "}
        <span className="verified-email">{companyEmail}</span> with your
        username and password.
      </p>
      <p className="verified-note">
        Please check your inbox and spam folder for the activation email.
      </p>
    </div>
  );
};

export default ActiveCompanyAccount;
