import {useContext} from "react";
import {UserContext} from "../../Providers/UserProvider";
import AdminUserProfile from "./admin-user-profile";
import UserProfile from "./UserProfile";

export function UserProfileWrapper() {
    const {admin} = useContext(UserContext);

    if (admin) {
        return <AdminUserProfile/>
    }

    return <UserProfile/>
}