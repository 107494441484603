import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

// import { withRouter } from "react-router-dom"
import { withRouter } from '../../withRouter';
import { firestore } from '../../Firebase';
import { spinner, showConfirmDialog, calculateRating } from '../../App';

import Spinner from '../ModalComponents/Spinner';
import ShimmerLoader from '../ModalComponents/ShimmerLoading';

class EditFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqId: this.props.match.params.faqId,
      type: '',
      title: '',
      content: '',
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchData(faqId) {
    firestore
      .collection('faqs')
      .doc(faqId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            type: doc.data().type,
            title: doc.data().title,
            content: doc.data().content,
          });
        } else {
          spinner(true);
          showConfirmDialog(
            true,
            'The FAQ you are looking for no longer exists!'
          );
        }
      });
  }

  validateUpdate() {
    let text_regex = /^[a-zA-Z0-9.,?!&\- ]*$/;
    let typeError = document.getElementById('typeError');
    let titleError = document.getElementById('titleError');
    let richError = document.getElementById('richError');

    if (this.state.type === '') {
      typeError.style.display = 'block';
      typeError.innerHTML =
        'Error: The type field contains characters or emojis that are not allowed.';
      return false;
    } else {
      typeError.style.display = 'none';
    }
    if (this.state.title === '' || !text_regex.test(this.state.title)) {
      titleError.style.display = 'block';
      titleError.innerHTML =
        'Error: The title field contains characters or emojis that are not allowed.';
      return false;
    } else {
      titleError.style.display = 'none';
    }
    if (this.state.content === '') {
      richError.style.display = 'block';
      richError.innerHTML =
        'Error: The content field contains characters or emojis that are not allowed.';
      return false;
    } else {
      richError.style.display = 'none';
    }
    return true;
  }

  handleEditorChange(e) {
    this.setState({ content: e.target.getContent() });
  }

  saveFaq() {
    if (!this.validateUpdate()) return false;

    let updateData = {
      type: this.state.type,
      title: this.state.title,
      content: this.state.content,
    };

    firestore
      .collection('faqs')
      .doc(this.state.faqId)
      .update(updateData)
      .then(() => {
        spinner(true);
        showConfirmDialog(false, 'Changes succesfully written to database!');
      })
      .catch((error) => {
        spinner(true);
        showConfirmDialog(true, `Changes failed: ${error}`);
      });
  }

  deleteFaq() {
    firestore
      .collection('faqs')
      .doc(this.state.faqId)
      .delete()
      .then(() => {
        spinner(true);
        showConfirmDialog(
          false,
          'Changes succesfully deleted FAQ from Database!'
        );
      })
      .catch((error) => {
        spinner(true);
        showConfirmDialog(true, `Changes failed: ${error}`);
      });
  }

  displayFaqInfo() {
    if (!this.state.type) {
      return <ShimmerLoader />;
    } else {
      return (
        <div className="edit-faq-container">
          <div className="user-data-content">
            <div className="user-data-content-info bold-title">Type:</div>
            <div className="user-data-content-info flex-column">
              <select
                className="user-data-content-info-input"
                name="type"
                value={this.state.type}
                onChange={this.handleInputChange}
              >
                <option value={'general'}>General</option>
                <option value={'sender'}>Sender</option>
                <option value={'traveller'}>Traveller</option>
              </select>
              <div id="typeError" className="ban-error-message"></div>
            </div>
          </div>
          <div className="user-data-content">
            <div className="user-data-content-info bold-title">Title:</div>
            <div className="user-data-content-info flex-column">
              <input
                className="user-data-content-info-input"
                name="title"
                value={this.state.title}
                onChange={this.handleInputChange}
              />
              <div id="titleError" className="ban-error-message"></div>
            </div>
          </div>
          <div className="user-data-content">
            <div className="user-data-content-info bold-title">Content:</div>
            <div className="user-data-content-info editor-container flex-column">
              <Editor
                initialValue={this.state.content}
                apiKey="xdpqo1c8k86yigxjmv90hb0jxkuanquoldiegd6bfqg2y5nn"
                init={{
                  height: 400,
                  menubar: false,
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help',
                }}
                onChange={this.handleEditorChange}
              />

              <div id="richError" className="ban-error-message"></div>
            </div>
          </div>

          <div className="action-buttons-container align-bottom">
            <div
              onClick={() => this.deleteFaq()}
              className="user-action-button ban-user-btn noselect"
            >
              Delete
            </div>
            <div
              onClick={() => this.saveFaq()}
              className="user-action-button save-user-btn noselect"
            >
              Save
            </div>
          </div>
        </div>
      );
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    this.fetchData(this.state.faqId);
  }

  render() {
    return (
      <div style={{ padding: '30px' }}>
        <Helmet>
          <title>Trippie Air | Edit FAQ: {this.state.faqId}</title>
          <meta name="description" content="Trippie Air | User Profiles" />
        </Helmet>
        <Spinner />
        <div>
          <h1 className="control-title">Edit FAQ: {this.state.faqId}</h1>
          {this.displayFaqInfo()}
        </div>
      </div>
    );
  }
}

export default withRouter(EditFaq);
