import {STRIPE_FEE_FIXED, STRIPE_FEE_PERCENT} from '../constants';

export const deliveryStatus = (status) => {
    if (!status) return;
    switch (status) {
        case 'created':
            return 'P';
        case 'pickup':
            return 'D';
        default:
            return 'On The Way';
    }
};

export function haveValue(value) {
    if (
        value !== false &&
        value !== undefined &&
        value !== null &&
        value !== 'undefined' &&
        // value !== 'null' &&
        value !== 0
    ) {
        return true;
    } else {
        return false;
    }
}

export const assignNextStatus = (status) => {
    if (!status) return;
    // ['created','pickup','on_the_way']
    switch (status) {
        case 'created':
            return 'pickup';
        case 'pickup':
            return 'on_the_way';
        default:
            break;
    }
};

const statusMap = new Map([
    [0, 'PENDING'],
    [1, 'PROCESSING'],
    [2, 'ACCEPTED'],
    [3, 'PICKED_UP'],
    [4, 'DELIVERED'],
    [5, 'COMPLETED'],
    [6, 'FINALISED'],
    [7, 'CANCELLED'],
    [8, 'ASSIGNED'],
    [9, 'REJECTED'],
    [10, 'SWITCH'],
]);

export function isCancelled(status) {
    return status === 7;
}

export function getStatus(number) {
    console.log(statusMap.get(number), 'number');
    return statusMap.get(number);
}

const companyStatus = new Map([
    ['0', 'PENDING'],
    ['1', 'VERIFIED'],
    ['2', 'REJECTED'],
]);

export function getCompanyStatus(number) {
    return companyStatus.get(number.toString());
}

export function getOriginalAmountFromChargeInCents(chargeWithFeeDollars) {
    const amountWithoutFee =
        (chargeWithFeeDollars - STRIPE_FEE_FIXED) / (1 + STRIPE_FEE_PERCENT);
    const final = chargeWithFeeDollars - amountWithoutFee;
    return final;
}

export const stripePortion = (offerAmount) => {
    let chargeWithFee =
        (offerAmount + STRIPE_FEE_FIXED) / (1 - STRIPE_FEE_PERCENT);

    const final = chargeWithFee - offerAmount;

    return final.toFixed(2);
};

export const netProfit = (total, second) => {
    const newTotal = parseFloat(total) - parseFloat(second);
    return newTotal / 100;
};

export const formatDate = (timestamp) => {
    console.log(timestamp, 'timestamp');
    if (!timestamp) {
        return 'NA';
    }
    const date = new Date(
        timestamp?._seconds * 1000 + timestamp?._nanoseconds / 1000000
    ).toLocaleDateString('en-NZ');
    return date;
};
// format date with time
export const formatDateWithTime = (timestamp) => {
    if (!timestamp) {
        return 'NA';
    }

    const date = new Date(
        timestamp?._seconds
            ? timestamp?._seconds * 1000
            : timestamp?._nanoseconds / 1000000
    );

    // Format date and time in 'en-NZ' locale with options
    const formattedDate = date.toLocaleString('en-NZ', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // Use 24-hour time format
    });

    return formattedDate;
};

export function convertMilliseconds(milliseconds) {
    const msInDay = 86400000;
    const msInMonth = 2592000000; // Approximation: 30 days per month
    const msInYear = 31556952000; // Approximation: 365.25 days per year

    const years = Math.floor(milliseconds / msInYear);
    milliseconds %= msInYear;

    const months = Math.floor(milliseconds / msInMonth);
    milliseconds %= msInMonth;

    const days = Math.floor(milliseconds / msInDay);

    let result = [];
    if (years > 0) result.push(`${years} year${years > 1 ? 's' : ''}`);
    if (months > 0) result.push(`${months} month${months > 1 ? 's' : ''}`);
    if (days > 0 || result.length === 0)
        result.push(`${days} day${days > 1 ? 's' : ''}`);

    return result.join(', ');
}

export const getStringSubstring = (str, length = 10) => {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    }
    return str;
};

export function formattedDeliveryAmount(delivery) {
    const amount = (delivery?.paidAmount / 100) || delivery?.price;
    return formatCurrency(amount);
}


export function formatCurrency(amount) {
    console.log(amount, 'amount');
    return `$${Number(amount).toFixed(2)}`;
}