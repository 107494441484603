import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { auth, firestore } from '../../Firebase';

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      count: 0,
    };
  }

  getNotificationsCount() {
    this.listener = firestore
      .collection('admin_notifications')
      .where('read', '==', false)
      .onSnapshot((querySelector) => {
        let unreadCount = querySelector.docs.length;
        this.setState({
          count: unreadCount,
        });
      });
  }

  componentDidMount() {
    this.getNotificationsCount();
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  render() {
    let count = this.state.count;

    return (
      <NavLink to="/Notifications" className="notification-bell">
        <i className="fas fa-bell"></i>
        <span
          id="notificationBellIcon"
          className="notification-bell-icon"
          style={{ display: count > 0 ? 'unset' : 'none' }}
        >
          {count}
        </span>
      </NavLink>
    );
  }
}
