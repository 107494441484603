const REQUEST_PENDING = 0;
const REQUEST_PROCESSING = 1;
const REQUEST_ACCEPTED = 2;
const REQUEST_PICKED_UP = 3;
const REQUEST_DELIVERED = 4;
const REQUEST_COMPLETE = 5;
const REQUEST_FINALISED = 6;
const REQUEST_CANCELLED = 7;
const REQUEST_ASSIGNED = 8;
const REQUEST_REJECT = 9;
const PAYMENT_SUCCESS = 1;
const PAYMENT_PENDING = 0;
const STRIPE_PUBLIC_KEY = 'pk_test_PQwqwZr4XNYS6jVE8sl60TB500Q70dJv87';
const STRIPE_FEE_FIXED = 0.3;
const STRIPE_FEE_PERCENT = 0.029;
const BASEURL = 'https://trippie-nz-admin.web.app';

export {
  REQUEST_REJECT,
  BASEURL,
  REQUEST_ASSIGNED,
  REQUEST_CANCELLED,
  REQUEST_FINALISED,
  REQUEST_PICKED_UP,
  REQUEST_COMPLETE,
  REQUEST_DELIVERED,
  REQUEST_ACCEPTED,
  REQUEST_PENDING,
  REQUEST_PROCESSING,
  STRIPE_PUBLIC_KEY,
  STRIPE_FEE_FIXED,
  STRIPE_FEE_PERCENT,
  PAYMENT_PENDING,
  PAYMENT_SUCCESS,
};
