import React from 'react';

export default function ProgressionBar(props) {
    let status = props.status;
    let lastUpdated = props.lastUpdated.toDate().toLocaleString();
    return (
        <div className="progress-card">
            <ul id="progressbar">
                <li className={`${status >= 0 ? "activeStatus " : ""}step0`}><b>Pending</b><br />{status === 0 ? lastUpdated : ""}</li>
                <li className={`${status >= 1 ? "activeStatus " : ""}step0`}><b>Processing Payment</b><br />{status === 1 ? lastUpdated : ""}</li>
                <li className={`${status >= 2 ? "activeStatus " : ""}step0`}><b>Accepted</b><br />{status === 2 ? lastUpdated : ""}</li>
                <li className={`${status >= 3 ? "activeStatus " : ""}step0`}><b>Picked up</b><br />{status === 3 ? lastUpdated : ""}</li>
                <li className={`${status >= 4 ? "activeStatus " : ""}step0`}><b>Delivered</b><br />{status === 4 ? lastUpdated : ""}</li>
                <li className={`${status >= 5 ? "activeStatus " : ""}step0`}><b>Complete</b><br />{status === 5 ? lastUpdated : ""}</li>
                <li className={`${status >= 6 ? "activeStatus " : ""}step0`}><b>Finalised</b><br />{status === 6 ? lastUpdated : ""}</li>
            </ul>
        </div>
    );
}
