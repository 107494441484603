import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom'
import { withRouter } from '../../withRouter';

import { handleBanUserModal, spinner, showConfirmDialog } from '../../App';
import { functions } from '../../Firebase';

class ModalBanUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: window.location.href.split('/').at(-1),
      banReason: '',
      banLengthSelector: 0,
      banTimeNumber: 1,
      banTimeSelector: 86400000,
      validate: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBanSubmit = this.handleBanSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  validateForm() {
    let text_regex = /^[a-zA-Z0-9., ]*$/;

    let banReasonError = document.getElementById('banReasonError');
    let banLengthError = document.getElementById('banLengthError');
    console.log('ban length error', banLengthError);

    if (this.state.banReason === '' || !text_regex.test(this.state.banReason)) {
      banReasonError.style.display = 'block';
      banReasonError.innerHTML =
        'Error: Ban reason field contains some bullshit!';
      return false;
    } else {
      banReasonError.style.display = 'none';
    }
    if (this.state.banTimeNumber < 1 || this.state.banTimeNumber > 99) {
      banLengthError.style.display = 'block';
      banLengthError.innerHTML =
        'Error: Ban length needs to be greater than 1 and less than 99!';
      return false;
    } else {
      console.log(banLengthError, 'banLengthError');
      banLengthError.style.display = 'none';
    }
    return true;
  }

  handleBanSubmit() {
    if (!this.validateForm()) return;
    var length =
      this.state.banTimeNumber *
      this.state.banTimeSelector *
      this.state.banLengthSelector;
    var disableUserAccount = functions.httpsCallable('disableUserAccount');
    let data = {
      userId: this.state.userId,
      reason: this.state.banReason,
      length: length,
    };
    spinner(true);
    disableUserAccount(data)
      .then(() => {
        showConfirmDialog(false, 'The ban hammer has spoken.');
        handleBanUserModal(false);
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
        handleBanUserModal(false);
      });
  }

  render() {
    if (!document.getElementById('timedBanDisplay')) {
    } else if (this.state.banLengthSelector === 0) {
      document
        .getElementById('timedBanDisplay')
        .classList.add('timed-ban-display');
    } else {
      document
        .getElementById('timedBanDisplay')
        .classList.remove('timed-ban-display');
    }

    return (
      <div
        id="banUserModal"
        className="modal-ban-user-background hide-ban-user"
      >
        <div className="modal-ban-user-container"  style={{'height':'500px'}}>
          <div className="modal-ban-user-content">
            <div>
              <h1 className="control-title small">
                Select the reason and length for ban.
              </h1>
              <div className="addressFields ban-user-fields">
                <label>
                  User ID
                  <input
                    disabled={true}
                    className="user-data-content-info-input"
                    name="userId"
                    value={this.state.userId}
                  />
                </label>
              </div>
              <div className="addressFields ban-user-fields">
                <label>
                  Ban Reason
                  <input
                    className="user-data-content-info-input"
                    name="banReason"
                    value={this.state.banReason}
                    onChange={this.handleInputChange}
                  />
                </label>
                <div id="banReasonError" className="ban-error-message"></div>
              </div>

              <div className="addressFields ban-user-fields">
                <label>
                  Ban Type
                  <select
                    className="user-data-content-info-input"
                    name="banLengthSelector"
                    value={this.state.banLengthSelector}
                    onChange={this.handleInputChange}
                  >
                    <option value={0}>Indefinately</option>
                    <option value={1}>Timed Ban</option>
                  </select>
                </label>
              </div>
              {this.state.banLengthSelector === '1' && (
                <div
                  id="timedBanDisplay"
                  className="addressFields ban-user-fields"
                >
                  <label>
                    Ban Length
                    <div className="ban-timer-container">
                      <input
                        type="number"
                        className="user-data-content-info-input small-input-10"
                        name="banTimeNumber"
                        value={this.state.banTimeNumber}
                        onChange={this.handleInputChange}
                        onKeyDown={(event) => event.preventDefault()}
                        min="1"
                        max="99"
                      />

                      <select
                        className="user-data-content-info-input large-input-90"
                        name="banTimeSelector"
                        value={this.state.banTimeSelector}
                        onChange={this.handleInputChange}
                      >
                        <option value={86400000}>Day(s)</option>
                        <option value={2592000000}>Month(s)</option>
                        <option value={31556952000}>Year(s)</option>
                      </select>
                    </div>
                  </label>
                </div>
              )}
              <div id="banLengthError" className="ban-error-message"></div>
            </div>
            <div className="action-buttons-container align-bottom">
              <div
                onClick={() => handleBanUserModal(false)}
                className="user-action-button ban-user-btn noselect"
              >
                Cancel
              </div>
              <div
                onClick={this.handleBanSubmit}
                className="user-action-button save-user-btn noselect"
              >
                Confirm Ban
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalBanUser);
