import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { FaTruckPickup } from 'react-icons/fa';
import { auth, firestore } from '../../Firebase';

const icons = {
  trip: 'fas fa-car',
  request: 'fas fa-parachute-box',
};

export default class ReportedTrips extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      reportsData: [],
      perPage: 20,
      currentPage: 0,
      offset: 0,
      listingType: 'trip',
      reportReasonSelector: false,
    };
  }

  filterListingType(listingType) {
    let itemBtn = document.getElementById('itemTypeBtn');
    let tripBtn = document.getElementById('tripTypeBtn');
    if (listingType == 'trip') {
      tripBtn.classList.add('active');
      itemBtn.classList.remove('active');
    } else {
      tripBtn.classList.remove('active');
      itemBtn.classList.add('active');
    }
    this.setState(
      {
        listingType: listingType,
      },
      () => {
        if (this.listener) {
          this.listener();
        }
        this.fetchReportData();
      }
    );
  }

  //I dont know how to filter using firebase.

  fetchReportData() {
    this.listener = firestore
      .collectionGroup('reports')
      .orderBy('listingId')
      .where('listingType', '==', this.state.listingType)
      .onSnapshot((docSnap) => {
        let listingReports = [];
        docSnap.forEach((docSnap) => {
          listingReports.push({ data: docSnap.data(), id: docSnap.id });
        });
        this.setState({
          reportsData: listingReports,
        });
        this.displayReportList();
      });
  }

  displayReportList() {
    const slice = this.state.reportsData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    const postData = slice.map((pd, k) => (
      <React.Fragment key={k}>
        <Link
          to={{
            pathname: `/ReportedListings/${pd.data.listingId}/${pd.id}`,
          }}
          className="report-link"
        >
          <div className="w10 reports-item-box flex-centered">
            <i className={icons[pd.data.listingType]}></i>
          </div>
          <div className="w30 reports-item-box">{pd.data.reportReason}</div>
          <div className="w30 reports-item-box flex-centered">
            {pd.data.reportTime.toDate().toLocaleString('en-NZ')}
          </div>
          <div className="w30 reports-item-box flex-centered">
            {pd.data.reporterDisplayName}
          </div>
        </Link>
      </React.Fragment>
    ));

    this.setState({
      pageCount: Math.ceil(this.state.reportsData.length / this.state.perPage),
      postData,
    });
  }

  componentDidMount() {
    this.fetchReportData();
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  render() {
    return (
      <div style={{ padding: '30px' }}>
        <Helmet>
          <title>Trippie Air | Reported Trips</title>
          <meta name="description" content="Trippie Air | Reported Trips" />
        </Helmet>
        <div>
          <h1 className="control-title">Reported Listings</h1>
          <div className="report-list-content">
            <div className="report-filter-container">
              <div className="filter-listing-type">
                <div
                  id="tripTypeBtn"
                  onClick={() => this.filterListingType('trip')}
                  className="w50 filter-listing-type-btn border-top-left active"
                >
                  {/* <i className="fas fa-plane"></i> */}
                  <FaTruckPickup size={30} className="icons-car" />
                  Trips
                </div>
                <div
                  id="itemTypeBtn"
                  onClick={() => this.filterListingType('request')}
                  className="w50 filter-listing-type-btn border-top-right"
                >
                  <i className="fas fa-box"></i>Requests
                </div>
              </div>
            </div>
            <div className="report-list-table">
              <div className="report-list-header">
                <div className="report-list-header-title w10">Listing Type</div>
                <div className="report-list-header-title w30">
                  Report Reason
                </div>
                <div className="report-list-header-title w30">Report Time</div>
                <div className="report-list-header-title w30">Reporter</div>
              </div>
              {this.state.postData}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
