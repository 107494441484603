import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {UserContext} from '../../Providers/UserProvider';
import {Button, Form} from 'react-bootstrap';
import {firestore, functions} from '../../Firebase';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';

export default function AdminUserProfile() {
    const {user} = useContext(UserContext);
    const [userInfo, setUserInfo] = useState(null);
    const [isFormSubmit, setFormSubmit] = useState(false);

    function fetchUserInfo() {
        firestore
            .collection('user_private')
            .doc(user.uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setUserInfo(doc.data());
                }
            });
    }

    // Validation schema using yup
    const schema = yup.object().shape({
        displayName: yup.string().required('Display name is required').min(3),
        firstName: yup.string().required('First name is required').min(2),
        surname: yup.string().required('Surname is required').min(2),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isSubmitting},
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            displayName: '',
            firstName: '',
            surname: '',
        },
    });

    useEffect(() => {
        fetchUserInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userInfo) {
            reset({
                ...userInfo,
            });
        }
    }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (data) => {
        setFormSubmit(true);
        const updateUserProfile = functions.httpsCallable('updateUserProfileInfo');
        updateUserProfile({
            userId: user.uid,
            private: data,
        })
            .then((res) => {
                alert('Profile updated successfully');
            })
            .catch((error) => {
                alert(error.message);
            })
            .finally(() => {
                setFormSubmit(false);
            });
    };

    return (
        <div style={{padding: '30px'}}>
            <Helmet>
                <title>{`${user.displayName} | Profile`}</title>
                <meta name="description" content="Trippie Air | User Profiles"/>
            </Helmet>
            <div>
                <h1 className="control-title">Profile</h1>
                <div className="profile-content">
                    <div>{user.displayName}</div>
                </div>
            </div>
            <div className="data-container">
                <div className="box-title-container">
                    <div style={{paddingTop: '10px', paddingBottom: '10px'}}></div>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="trip-data" style={{maxWidth: '100%'}}>
                        <div id="tripFlightDetails">
                            <div className="depart-arrive-details">
                                <div className="depart-details">
                                    <div className="info-company">
                                        <Form.Group className="mb-3" controlId="displayName">
                                            <Form.Label>Display Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Display Name"
                                                {...register('displayName')}
                                            />
                                            {errors?.displayName && (
                                                <p className="error">{errors?.displayName?.message}</p>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="firstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                {...register('firstName')}
                                            />
                                            {errors?.firstName && (
                                                <p className="error">{errors?.firstName?.message}</p>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="surname">
                                            <Form.Label>Surname</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Surname"
                                                {...register('surname')}
                                            />
                                            {errors?.surname && (
                                                <p className="error">{errors?.surname?.message}</p>
                                            )}
                                        </Form.Group>
                                        <div className="d-flex gap-2 justify-content-end">
                                            <Button
                                                className="btn btn-primary px-4 py-2"
                                                type="submit"
                                                disabled={isSubmitting || isFormSubmit}
                                            >
                                                {isFormSubmit ? 'Submitting' : 'Submit'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}