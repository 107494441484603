import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import { auth, firestore } from "../../Firebase";
import { UserContext } from "../../Providers/UserProvider";

class AdminManagement extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.listener = null;
    this.isLogistic = null;
    this.state = {
      offset: 0,
      data: [],
      perPage: 9,
      currentPage: 0,
    };
  }

  fetchData() {
    let query = firestore.collection("user_admin");
    if (this.context.isThirdParty) {
      query = query.where("company", "==", this.context.admin.company);
    }
    this.listener = query.onSnapshot((querySelector) => {
      let userList = [];
      querySelector.forEach((doc) => {
        let userData = {
          userId: doc.id,
          userData: doc.data(),
        };
        userList.push(userData);
      });
      this.setState({ data: userList }, () => {
        this.paginateData();
      });
    });
  }

  paginateData() {
    const slice = this.state.data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    const postData = slice.map((pd, k) => (
      <React.Fragment key={k}>
        <div 
          className="user-profile-card"
          onClick={() => this.props.navigate(`/ViewAdminUser/${pd.userId}`)}
          style={{ cursor: 'pointer' }}
        >
          <div
            style={{
              textDecoration: "none",
              color: "#505050",
              display: "flex",
              flexDirection: "column",
            }}
            className="user-profile-card-content"
          >
            <div className="traveller-details-container">
              <div className="traveller-details">
                <div className="details-img">
                  <i className="far fa-user-circle"></i>
                </div>
                <div className="info">
                  <div className="info-content bold-title">
                    {pd.userData.displayName}
                  </div>
                  <div className="info-content">{pd.userData.email}</div>
                  <div className="info-content">
                    Created:{" "}
                    {pd.userData.dateCreated?.toDate().toLocaleString("en-NZ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
    this.setState({
      pageCount: Math.ceil(this.state.data.length / this.state.perPage),
      postData,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.paginateData();
      }
    );
  };

  componentDidMount() {
    this.fetchData();
    this.isLogistic = this.context.isThirdParty;
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  render() {
    const { isLogistic } = this.state;
    console.log(isLogistic, "isLogistic");

    return (
      <div style={{ padding: "30px" }}>
        <Helmet>
          <title>Trippie Air | Admin Accounts</title>
          <meta name="description" content="Trippie Air | User Profiles" />
        </Helmet>
        <div>
          <h1 className="control-title">Admin Accounts</h1>
          <div className="create-user-list">
            <div className="change-trip-display-container">
              {!this.context.isThirdParty && (
                <div className="change-buttons">
                  <Link
                    to="/AdminUsers/Create"
                    className="change-trip-display-button noselect"
                    style={{ textDecoration: "none" }}
                  >
                    Create new Admin Account
                  </Link>
                </div>
              )}
            </div>
            <div className="user-profile-container">
              {this.state.postData}
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={<i className="fas fa-arrow-left"></i>}
                  nextLabel={<i className="fas fa-arrow-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function(props) {
  const navigate = useNavigate();
  return <AdminManagement {...props} navigate={navigate} />;
}
