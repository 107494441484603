import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { spinner, showConfirmDialog } from '../../App';
import { auth, functions, firestore } from '../../Firebase';
import Spinner from '../ModalComponents/Spinner';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { updateCompanyProfile } from '../../services/logistic-services';
import BanModelCompanies from '../Models/BanModel';
import RejectModel from '../Models/RejectModel';
import GreenCheck from '../../img/greencheck.png';
import RedCheck from '../../img/redcross.png';
import { UserContext } from '../../Providers/UserProvider';
function CompanyDetails() {
  const { isThirdParty } = useContext(UserContext);

  const { tripId } = useParams();
  const [tripData, setTripData] = useState(null);
  const [pending, setPending] = useState(true);
  const [openModelUnban, setOpenModelUnban] = useState(false);
  const [reason, setReason] = useState(null);
  const [openModelReject, setOpenModelReject] = useState(false);
  const [openModelBan, setOpenModelBan] = useState(false);
  useEffect(() => {
    let listener = null;

    const fetchData = (id) => {
      if (listener) {
        listener();
      }
      listener = firestore
        .collection('companies')
        .doc(id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setTripData(doc.data());
          } else {
            if (pending) {
              setPending(false);
              fetchData(id);
            } else {
              spinner(true);
              showConfirmDialog(true, 'This trip no longer exists!');
            }
          }
        });
    };

    fetchData(tripId);

    const authListener = auth.onAuthStateChanged((user) => {
      if (!user && listener) {
        listener();
      }
    });

    return () => {
      if (listener) listener();
      authListener();
    };
  }, [tripId, pending]);

  const authTrip = () => {
    var authorisePendingTrip = functions.httpsCallable(
      'sendActiveCompanyEmail'
    );
    spinner(true);
    authorisePendingTrip({ companyId: tripId })
      .then(() => {
        showConfirmDialog(
          false,
          'Activation email has been sent please wait for confirmation!'
        );
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  };

  const declineTrip = () => {
    var declinePendingTrip = functions.httpsCallable(
      'sendRejectedCompanyEmail'
    );
    if (!reason) {
      alert('Please enter reason for reject');
      return;
    }
    setOpenModelReject(false);

    spinner(true);
    declinePendingTrip({ companyId: tripId, reason: reason })
      .then(() => {
        showConfirmDialog(false, 'Company profile Rejected');
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setTripData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAuthButtons = (tripData) => {
    if (tripData.verified === 0) {
      return (
        <div className="authorisation-controls">
          <div className="auth-50-percent auth-btn-box">
            <div className="confirmation-container" onClick={authTrip}>
              <img src={GreenCheck} alt="accept" className="auth-icons" />
            </div>
            Approve
          </div>
          <div className="auth-50-percent auth-btn-box">
            <div
              className="confirmation-container"
              onClick={() => setOpenModelReject(true)}
            >
              <img src={RedCheck} alt="accept" className="auth-icons" />
            </div>
            Reject
          </div>
        </div>
      );
    }
    return null;
  };

  const updateDataCompanyProfile = () => {
    console.log('update working');
    spinner(true);
    // updateCompanyProfile(tripId, tripData)
    //   .then((doc) => {
    //     showConfirmDialog(false, 'Company profile updated successfully');
    //   })
    //   .catch((err) => {
    //     console.log('err while ', err);
    //     showConfirmDialog(true, err.message);
    //   });
    const updateDataCompanyProfile = functions.httpsCallable(
      'updateCompanyProfile'
    );
    let data = {
      ...tripData,
      companyId: tripId,
    };
    updateDataCompanyProfile(data)
      .then((res) => {
        console.log('res', res);
        showConfirmDialog(false, 'Company profile updated successfully');
      })
      .catch((error) => {
        console.log('error', error);
        showConfirmDialog(true, error.message);
      });
  };

  console.log('tripData', tripData);
  if (!tripData) return null;

  return (
    <div className="trip-details-background" id="loadTripDetails">
      <Spinner />
      <Helmet>
        <title>Company Details | Company ID: {tripId}</title>
        <meta name="description" content="Trippie Air | Authorise new trips" />
      </Helmet>
      <div className="trip-details-container">
        <h1 className="control-title">Logistic Company Information</h1>

        <div className="data-container">
          <div className="box-title-container">
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              {!tripData?.isEmailSend && handleAuthButtons(tripData)}
            </div>
          </div>
          <div>
            <div className="trip-data" style={{ maxWidth: '100%' }}>
              <div id="tripFlightDetails">
                <div className="depart-arrive-details">
                  <div className="depart-details">
                    <div className="info-company">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          value={tripData.companyName}
                          onChange={handleFormChange}
                          name="companyName"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Address 1</Form.Label>
                        <Form.Control
                          type="textarea"
                          placeholder="Address 1"
                          value={tripData.street}
                          onChange={handleFormChange}
                          name="street"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Address 2</Form.Label>
                        <Form.Control
                          type="textarea"
                          placeholder="address"
                          value={tripData.address}
                          onChange={handleFormChange}
                          name="address"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Company Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address"
                          value={tripData.phoneNumber}
                          onChange={handleFormChange}
                          name="phoneNumber"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Contact Person Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address"
                          value={tripData.contactPersonName}
                          onChange={handleFormChange}
                          name="contactPersonName"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address"
                          value={tripData.contactPersonJobTitle}
                          onChange={handleFormChange}
                          name="contactPersonJobTitle"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address"
                          value={tripData.contactPersonPhoneNumber}
                          onChange={handleFormChange}
                          name="contactPersonPhoneNumber"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Contact Person Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="address"
                          value={tripData.contactPersonEmail}
                          onChange={handleFormChange}
                          name="contactPersonEmail"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Company Code</Form.Label>
                        <Form.Control
                          type="text"
                          readOnly
                          value={tripData.companyCode || 'N/A'}
                          // onChange={handleFormChange}
                          name="companyCode"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Commercial License </Form.Label>
                        {tripData.commercialLicenseUrl ? (
                          <a
                            href={tripData.commercialLicenseUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <span style={{ marginLeft: 10 }}>Download</span>
                          </a>
                        ) : (
                          <Form.Control
                            type="file"
                            placeholder="address"
                            value={tripData.commercialLicenseUrl}
                            onChange={handleFormChange}
                            name="commercialLicenseUrl"
                          />
                        )}
                      </Form.Group>

                      <div className="action-buttons-container">
                        {tripData?.verified === 1 && (
                          <>
                            {tripData?.isBanned ? (
                              <div
                                className="user-action-button ban-user-btn noselect"
                                onClick={() => setOpenModelUnban(true)}
                              >
                                Unban
                              </div>
                            ) : (
                              <div
                                className="user-action-button save-user-btn noselect"
                                onClick={() => setOpenModelBan(true)}
                              >
                                Ban
                              </div>
                            )}
                          </>
                        )}
                        {tripData?.verified !== 2 && (
                          <div
                            className="user-action-button save-user-btn noselect"
                            onClick={updateDataCompanyProfile}
                          >
                            Save
                          </div>
                        )}
                      </div>
                      {tripData.isBanned ? (
                        <BanModelCompanies
                          openModel={openModelUnban}
                          setOpenModel={setOpenModelUnban}
                          companyId={tripId}
                          companyData={tripData}
                        />
                      ) : (
                        <BanModelCompanies
                          openModel={openModelBan}
                          setOpenModel={setOpenModelBan}
                          companyId={tripId}
                          companyData={tripData}
                        />
                      )}

                      <RejectModel
                        show={openModelReject}
                        handleClose={() => setOpenModelReject(false)}
                        handleSubmit={declineTrip}
                        inputData={{
                          setReason,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
