import React, {useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {spinner, showConfirmDialog} from '../../App';
import {auth, firestore, functions} from '../../Firebase';
import Spinner from '../ModalComponents/Spinner';

const CreateUser = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        displayName: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirm: '',
    });

    const [logisticList, setLogisticList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        let listener = null;
        const fetchLogisticList = () => {
            listener = firestore
                .collection('companies')
                .where('verified', '==', 1)
                .onSnapshot((querySelector) => {
                    let logistic = [];
                    querySelector.forEach((doc) => {
                        const company = doc.data();
                        let userData = {
                            value: String(doc.id),
                            label: String(company.companyName),
                        };
                        logistic.push(userData);
                    });
                    setLogisticList(logistic);
                    setSelectedCompany(logistic[0]);
                });
        };

        fetchLogisticList();

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user && listener) {
                listener();
            } else if (user && logisticList.length === 0) {
                fetchLogisticList();
            }
        });

        return () => {
            unsubscribe();
            if (listener) listener();
        };
    }, [logisticList.length]);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const validateForm = () => {
        let text_regex = /^[a-zA-Z ]*$/;
        let text_num_regex = /^[a-zA-Z0-9]*$/;
        let phone_regex = /^[0-9]*$/;
        let email_regex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let password_regex = /^[a-zA-Z0-9!@#$%^&*()]*$/;

        let textError = 'Error: Fields contains invalid characters or is empty';
        let phoneError =
            'Error: Field contains something other than numbers or is empty';
        let emailError = 'Error: Does this look like a email to you?';
        let passwordError = 'Error: The passwords you have entered do not match';

        let firstName = document.getElementById('firstName');
        let lastName = document.getElementById('lastName');
        let phoneNumber = document.getElementById('phoneNumber');
        let displayName = document.getElementById('displayName');
        let email = document.getElementById('email');
        let password = document.getElementById('password');
        let confirmPass = document.getElementById('confirmPass');

        // Perform all the validations (similar to the class component)
        // Update the error messages and styles accordingly

        // Example validation (repeat for all fields):
        if (formData.firstName === '' || !text_regex.test(formData.firstName)) {
            firstName.style.display = 'block';
            firstName.innerHTML = textError;
            return false;
        } else {
            firstName.style.display = 'none';
        }
        return true;
    };

    const handleSubmit = () => {
        if (!validateForm()) return;

        const createNewAdminUser = functions.httpsCallable('createNewAdminUser');
        const data = {
            ...formData,
            surname: formData.lastName,
            company: selectedCompany?.value,
        };

        spinner(true);
        createNewAdminUser({userData: data})
            .then(() => {
                showConfirmDialog(false, 'Admin account created successfully');
                setFormData({
                    firstName: '',
                    lastName: '',
                    displayName: '',
                    phone: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                });
                return <Navigate to="/AdminUsers"/>;
            })
            .catch((error) => {
                showConfirmDialog(true, error.message);
            });
    };

    const createNewUserForm = () => (
        <div className="user-data">
            <div className="user-data-container">
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">First Name:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                        />
                        <div id="firstName" className="ban-error-message"></div>
                    </div>
                </div>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">Last Name:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                        />
                        <div id="lastName" className="ban-error-message"></div>
                    </div>
                </div>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">Phone Number:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                        <div id="phoneNumber" className="ban-error-message"></div>
                    </div>
                </div>
                <br/>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">Display Name:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="displayName"
                            value={formData.displayName}
                            onChange={handleInputChange}
                        />
                        <div id="displayName" className="ban-error-message"></div>
                    </div>
                </div>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">Email:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        <div id="email" className="ban-error-message"></div>
                    </div>
                </div>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">Password:</div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        <div id="password" className="ban-error-message"></div>
                    </div>
                </div>
                <div className="user-data-content">
                    <div className="user-data-content-info bold-title">
                        Confirm Password:
                    </div>
                    <div className="user-data-content-info">
                        <input
                            className="user-data-content-info-input"
                            name="passwordConfirm"
                            type="password"
                            value={formData.passwordConfirm}
                            onChange={handleInputChange}
                        />
                        <div id="confirmPass" className="ban-error-message"></div>
                    </div>
                </div>

                <div className="action-buttons-container">
                    <div
                        className="user-action-button save-user-btn noselect"
                        onClick={handleSubmit}
                    >
                        Create
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div style={{margin: '30px'}}>
            <Spinner/>
            <div className="customer-info">
                <div className="customer-info-title">New User Details</div>
                {createNewUserForm()}
            </div>
        </div>
    );
};

export default CreateUser;
