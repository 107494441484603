import React, {Component} from 'react';

export default class ShimmerLoader extends Component {
  render(){
    return(
      <div className="shimmer-card">
        <div className="shimmer-wrapper">
          <div className="shimmer-profile-pic animate"></div>
          <div className="shimmer-content animate w80"></div>
          <div className="shimmer-content animate"></div>
          <div className="shimmer-content animate"></div>
        </div>
      </div>
    )
  }
}
