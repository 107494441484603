import { auth, firestore } from '../Firebase';

async function getActiveAccountIds() {
  try {
    const activeAccountIds = [];
    const querySnapshot = await firestore
      .collection('connect_accounts')
      .where('transfersStatus', '==', 'active')
      .get();

    querySnapshot.forEach((doc) => {
      activeAccountIds.push(doc.id);
    });

    return activeAccountIds;
  } catch (error) {
    throw new Error('Error fetching active account IDs: ' + error.message);
  }
}

export async function driveList(notInclude = []) {
  try {
    const activeAccountIds = await getActiveAccountIds();
    const userPrivateRef = firestore.collection('user_private');
    const userDocs = await Promise.all(
      activeAccountIds.map((userId) => userPrivateRef.doc(userId).get())
    );
    const activeUsers = userDocs
      .filter((doc) => doc.exists)
      .filter((doc) => !notInclude.includes(doc.id))
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return activeUsers;
  } catch (error) {
    throw new Error('Error fetching active users: ' + error.message);
  }
}
export const getUserById = async (userId) => {
  try {
    const userDoc = await firestore
      .collection('user_private')
      .doc(userId)
      .get();

    if (userDoc.exists) {
      return userDoc.data();
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    throw error;
  }
};

export const adminInfo = async (userId) => {
  try {
    const admin = await firestore.collection('user_admin').doc(userId).get();
    if (admin.exists) {
      return admin.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('error while fetch ', error);
  }
};

export const isDriver = async (userId) => {
  try {
    const docSnapshot = await firestore
      .collection('connect_accounts')
      .doc(userId)
      .get();
    return docSnapshot.data();
  } catch (error) {
    console.error('Error checking driver status:', error);
    return false;
  }
};

export async function checkDrivers(userIds) {
  try {
    const batch = userIds.map((userId) =>
      firestore.collection('connect_accounts').doc(userId).get()
    );
    const snapshots = await Promise.all(batch);
    return snapshots.map((snapshot) => ({
      userId: snapshot.id,
      exists: snapshot.exists,
    }));
  } catch (error) {
    console.error('Error checking driver status:', error);
    return [];
  }
}

export const fetchCompanies = async () => {
  try {
    const querySnapshot = await firestore
      .collection('companies')
      .where('verified', '==', 1)
      .get();
    let list = [];
    querySnapshot.forEach((doc) => {
      list.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    console.log(list, 'list');
    return list;
  } catch (error) {
    console.error('Error fetching companies:', error);
    return [];
  }
};

export const fetchCompanyById = async (companyId) => {
  try {
    const docSnapshot = await firestore
      .collection('companies')
      .doc(companyId)
      .get();
    if (docSnapshot.exists) {
      return docSnapshot.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching company:', error);
    return null;
  }
};

export const checkIfUserIsDisabled = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user is currently signed in.');
    }
    const tokenResult = await user.getIdTokenResult();
    if (tokenResult.claims && tokenResult.claims.admin) {
      return false;
    }
    return false;
  } catch (error) {
    if (error.code === 'auth/user-disabled') {
      return true;
    } else {
      console.error('Error checking user status:', error.message);
      return false;
    }
  }
};
