import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { STRIPE_PUBLIC_KEY } from "../../constants";
import { functions } from "../../Firebase";
import Loader from "../Loader";
import PaymentForm from "../PaymentForm";

const stripe = loadStripe(STRIPE_PUBLIC_KEY);
const Recharge = ({ open, onClose, userId }) => {
  const [clientSecret, setClientSecert] = useState(null);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const options = {
    clientSecret: clientSecret,
    theme: "stripe",
  };
  const handleClientSecret = (amount) => {
    if (amount <= 10) {
      alert("Add Should be more than 10");
    }

    const getSecert = functions.httpsCallable("walletTopUp");
    let data = {
      amount: amount,
      userId: userId,
    };
    setIsLoading(true);
    getSecert(data)
      .then((res) => {
        console.log("res while secret", res);
        setIsLoading(false);
        setClientSecert(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "err while topup");
      });
  };
  return (
    <div>
      <Modal show={open} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Wallet TopUp</Modal.Title>
        </Modal.Header>

        {isLoading ? (
          <Modal.Body>
            <Loader show={isLoading ? "show" : null} />
          </Modal.Body>
        ) : (
          <>
            {clientSecret ? (
              <>
                <Modal.Body>
                  <Elements stripe={stripe} options={options}>
                    <PaymentForm
                      onClose={() => {
                        setClientSecert(null);
                        onClose();
                      }}
                      userId={userId}
                    />
                  </Elements>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Body>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Amount </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => handleClientSecret(amount)}>
                    Next
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </Modal.Footer>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default Recharge;
