import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {FaTruckPickup, FaUserTag} from 'react-icons/fa';
import {auth, firestore, functions} from '../../Firebase';
import {UserContext} from '../../Providers/UserProvider';
import Select from 'react-select';
import Loader from '../Loader';
import {Form} from 'react-bootstrap';
import {haveValue} from '../../utils/helper';

export default class UserProfile extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.listener = null;
        this.admin = null;
        this.isLogisticCompany = null;

        this.roleOptions = [
            {label: 'All Users', value: null},
            {label: 'CO Customers', value: 'user'},
            {label: 'Driver', value: 'driver'},
            {label: 'PR-Drivers', value: 'pr-driver'},
            {label: 'Individual Customers', value: 'individual'},
        ];

        this.state = {
            loading: false,
            offset: 0,
            data: [],
            originalData: [],
            perPage: 15,
            currentPage: 0,
            selectRole: null,
            search: '',
        };
    }

    async paginateData() {
        const slice = this.state.data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
        );
        const postData = await Promise.all(
            slice.map(async (pd, k) => {
                if (haveValue(pd))
                    return (
                        <React.Fragment key={k}>
                            <div className="user-profile-card">
                                <Link
                                    to={{
                                        pathname: `/AccountDetails/${pd?.userId}`,
                                    }}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#505050',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    className="user-profile-card-content"
                                >
                                    <div className="traveller-details-container">
                                        <div className="traveller-details">
                                            <div className="driver-tag">
                                                {pd.isTraveller && (
                                                    <div className="driver-identifier">
                                                        <FaTruckPickup size={25} color="#01a2cc"/>
                                                        <span>{pd.traveller?.type.toUpperCase()}</span>{' '}
                                                    </div>
                                                )}
                                                {pd.private.senderType !== 'individual' &&
                                                    !pd.isTraveller && (
                                                        <div className="driver-identifier">
                                                            <FaUserTag size={20} color="#01a2cc"/>
                                                            <span>Co Customer</span>{' '}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="details-img">
                                                {pd.public.profileImgUrl ? (
                                                    <img
                                                        className="user-profile-image"
                                                        src={pd.public?.profileImgUrl}
                                                        alt="user-profile"
                                                    />
                                                ) : (
                                                    <i className="far fa-user-circle"></i>
                                                )}
                                            </div>
                                            <div className="info">
                                                <div className="info-content bold-title">
                                                    {pd.public?.displayName}
                                                </div>

                                                <div className="info-content">
                                                    Bio: {pd.public?.aboutMe}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </React.Fragment>
                    );
            })
        );
        this.setState({
            postData,
        });
    }

    getAllUserDetails = async (userId) => {
        try {
            const getAllUserDetails = functions.httpsCallable('getAllUserData');
            const data = await getAllUserDetails({userId});
            return data.data
        } catch (error) {
            console.log("error", error)
            return null;
        }
        ;
    };

    fetchData() {
        let query = firestore.collection('user_public');
        this.setState({loading: true});
        if (this.context.isThirdParty) {
            query = query.where('companyId', '==', this.admin.company);
        }

        this.listener = query.onSnapshot(async (querySelector) => {
            let userDocs = [];
            querySelector.forEach((doc) => {
                userDocs.push({
                    userId: doc.id,
                    userData: doc.data(),
                });
            });

            const driverChecks = userDocs.map(async (user) => {
                    const usr = await this.getAllUserDetails(user?.userId)
                    if (haveValue(usr)) {
                        return usr;
                    }

                }
            );
            let userList = await Promise.all(driverChecks);

            if (
                this.state.selectRole &&
                this.state.selectRole.value !== null &&
                this.state.selectRole.value === 'user'
            ) {
                userList = userList
                    .filter(user => user !== undefined)
                    .filter(
                        (user) =>
                            user.isTraveller === false &&
                            user.private.senderType !== 'individual'
                    );
            }
            if (
                this.state.selectRole &&
                this.state.selectRole.value !== null &&
                this.state.selectRole.value === 'individual'
            ) {
                userList = userList
                    .filter(user => user !== undefined)
                    .filter(
                        (user) =>
                            user.isTraveller === false &&
                            user.private.senderType === 'individual'
                    );
            }

            if (
                this.state.selectRole &&
                this.state.selectRole.value === 'pr-driver'
            ) {
                userList = userList
                    .filter(user => user !== undefined)
                    .filter(
                        (user) => user.isThirdPartyLogisticDriver === true
                    );
            }
            if (
                this.state.selectRole &&
                this.state.selectRole.value !== null &&
                this.state?.selectRole.value === 'driver'
            ) {
                userList = userList
                    .filter(user => user !== undefined)
                    .filter(
                        (user) =>
                            user.isThirdPartyLogisticDriver === false &&
                            user.isTraveller === true
                    );
            }

            if (this.context.isThirdParty) {
                userList = userList
                    .filter(user => user !== undefined)
                    .filter(
                        (user) => user.isThirdPartyLogisticDriver === true
                    );
            }

            this.setState(
                {
                    data: userList,
                    originalData: userList,
                    pageCount: Math.ceil(userList.length / this.state.perPage),
                },
                () => {
                    this.paginateData();
                    this.setState({loading: false});
                }
            );
        });
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.paginateData();
            }
        );
    };

    componentDidMount() {
        this.admin = this.context.admin;
        if (this.admin) {
            this.fetchData();
        }
        auth.onAuthStateChanged((user) => {
            if (!user && this.listener) {
                this.listener();
            }
        });
    }

    componentDidUpdate(prevProp, prevState) {
        if (this.state.selectRole !== prevState.selectRole) {
            this.fetchData();
            this.setState(
                {
                    currentPage: 0,
                    offset: 0,
                },
                () => {
                    this.fetchData();
                }
            );
        }

        if (this.state.search !== prevState.search) {
            this.performSearch();
        }
    }

    performSearch() {
        const {search, data: originalData} = this.state;
        console.log(search, 'search', originalData);
        if (!search.trim()) {
            this.setState(
                {
                    currentPage: 0,
                    offset: 0,
                },
                () => {
                    this.fetchData();
                }
            );
            return;
        }

        const lowercasedSearch = search.toLowerCase();
        const filteredData = originalData.filter((user) => {
            if (user && user.public && user.public.displayName) {
                return user.public.displayName.toLowerCase().includes(lowercasedSearch);
            }
            return false;
        });

        this.setState({data: filteredData}, () => {
            this.paginateData();
        });
    }

    render() {
        return (
            <div style={{padding: '30px'}}>
                <Helmet>
                    <title>Trippie Air | Driver Accounts</title>
                    <meta name="description" content="Trippie Air | User Profiles"/>
                </Helmet>
                <div>
                    <div className="flex">
                        <h1 className="control-title">
                            {this.context.isThirdParty ? 'Driver' : 'User'} Accounts
                        </h1>
                        <div className="flex">
                            <Form.Control
                                type="text"
                                placeholder="Search User"
                                className="search-user"
                                onChange={(e) => this.setState({search: e.target.value})}
                                style={{
                                    width: '300px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                }}
                            />
                            {!this.context.isThirdParty && (
                                <Select
                                    defaultValue={this.state.selectRole}
                                    onChange={(select) =>
                                        this.setState({
                                            selectRole: select,
                                        })
                                    }
                                    options={this.roleOptions}
                                    placeholder="Select User Role"
                                />
                            )}
                        </div>
                    </div>
                    <div className="user-profile-list">
                        {this.state.loading ? (
                            <Loader show={this.state.loading ? 'show' : null}/>
                        ) : (
                            <div className="user-profile-container">
                                {this.state.postData}
                            </div>
                        )}
                        <div className="pagination-container">
                            <ReactPaginate
                                previousLabel={<i className="fas fa-arrow-left"></i>}
                                nextLabel={<i className="fas fa-arrow-right"></i>}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
