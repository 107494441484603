import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { auth, firestore } from '../../Firebase';
import { BASEURL } from '../../constants';

const icons = {
  new_trip: 'fas fa-car',
  new_traveller: 'fas fa-user-plus',
  new_user: 'fas fa-user-ninja',
  trip_report: 'fas fa-flag',
  request_report: 'fas fa-parachute-box',
  delivery_update: 'fas fa-info-circle',
  rating: 'fas fa-star',
};

const routes = {
  new_trip: '/TripDetails/',
  new_traveller: '/AccountDetails/',
  new_driver: '/AccountDetails/',
  user_profile_update: '/AccountDetails/',
  new_user: '/AccountDetails/',
  trip_report: '/ReportedListings/',
  request_report: '/ReportedListings/',
  delivery_update: '/Delivery/',
  logistic_partner_profile_updated: '/CompanyDetails/',
};

const filterNotificationOption = [
  { label: 'All', value: null },
  { label: 'Delivery Request', value: 'new_trip' },
  { label: 'Driver Register', value: 'new_traveller' },
  { label: 'User Register', value: 'new_user' },
  { label: 'Delivery Report', value: 'trip_report' },
  { label: 'User Profile Update', value: 'user_profile_update' },
  { label: 'Delivery Update', value: 'delivery_update' },
  { label: 'Rating', value: 'rating' },
  {
    label: 'Logistic Company Profile',
    value: 'logistic_partner_profile_updated',
  },
];
const Notifications = () => {
  let listener = null;
  const [notifications, setNotifications] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [postData, setPostData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const navigate = useNavigate();

  const fetchAllNotifications = () => {
    if (listener) {
      listener(); // Unsubscribe from previous listener
    }

    let query = firestore
      .collection('admin_notifications')
      .orderBy('read', 'asc')
      .orderBy('timestamp', 'desc');

    if (selectedFilter) {
      query = query.where('type', '==', selectedFilter.value);
    }

    listener = query.onSnapshot((querySnapshot) => {
      let notificationsList = [];
      querySnapshot.forEach((docSnap) => {
        let docData = docSnap.data();
        notificationsList.push({
          data: docData,
          id: docSnap.id,
        });
      });
      setNotifications(notificationsList);
      setOffset(0); // Reset offset when new data is fetched
      displayNotificationsList();
    });
  };

  const updateNotificationStatus = (notification) => {
    if (!notification.data.read) {
      let notificationRef = firestore
        .collection('admin_notifications')
        .doc(notification.id);
      notificationRef.update({ read: true });
    }
  };

  const handleFilterChange = (selectedFilter) => {
    let filter = selectedFilter;
    if (filter.value === null) {
      filter = null;
    }
    setSelectedFilter(filter);
    fetchAllNotifications(); // Re-fetch notifications based on the new filter
  };

  useEffect(() => {
    fetchAllNotifications();
    auth.onAuthStateChanged((user) => {
      if (!user && listener) {
        listener();
      }
    });
  }, [selectedFilter]);

  const displayNotificationsList = () => {
    const slice = notifications.slice(offset, offset + perPage);
    const postData = slice.map((pd, k) => {
      console.log('pd', pd.data);
      let pathname = `${routes[pd.data.type] + pd.data.extraData}`;
      console.log(pathname, 'pathname');
      if (pd.data.type === 'delivery_update') {
        pathname = `${
          routes[pd.data.type] +
          pd.data.extraData.requestId +
          '/' +
          pd.data.extraData.senderId
        }`;
      }

      return (
        <React.Fragment key={k}>
          <Link
            to={pathname}
            onClick={() => updateNotificationStatus(pd)}
            className="notification-link"
          >
            <div className="notification-item-box notif-10-width flex-centered">
              <i className={icons[pd.data.type]}></i>
            </div>
            <div className="notification-item-box notif-40-width">
              {pd.data.message}
            </div>
            <div className="notification-item-box notif-40-width flex-centered">
              {pd.data.timestamp.toDate().toLocaleString('en-NZ')}
            </div>
            <div className="notification-item-box notif-10-width flex-centered">
              {pd.data.read ? (
                <i className="far fa-envelope-open"></i>
              ) : (
                <i className="fas fa-envelope"></i>
              )}
            </div>
          </Link>
        </React.Fragment>
      );
    });

    setPostData(postData);
    setPageCount(Math.ceil(notifications.length / perPage));
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
    displayNotificationsList();
  };

  useEffect(() => {
    displayNotificationsList();
  }, [notifications]);

  return (
    <div style={{ padding: '30px' }}>
      <Helmet>
        <title>Trippie Air | Notifications</title>
        <meta name="description" content="Trippie Air | User Profiles" />
      </Helmet>
      <div>
        <div className="notification-filter">
          <h1 className="control-title">Recent Notifications</h1>
          <Select
            defaultValue={selectedFilter}
            onChange={handleFilterChange}
            options={filterNotificationOption}
          />
        </div>
        <div className="notifications-content">
          <div className="notifications-list-table">
            <div className="notifications-list-header">
              <div className="notifications-list-header-title notif-10-width"></div>
              <div className="notifications-list-header-title notif-40-width">
                Notification
              </div>

              <div className="notifications-list-header-title notif-40-width">
                Date Recieved
              </div>
              <div className="notifications-list-header-title notif-10-width"></div>
            </div>
            {postData}
          </div>
          <div className="pagination-container no-shadow">
            <ReactPaginate
              previousLabel={<i className="fas fa-arrow-left"></i>}
              nextLabel={<i className="fas fa-arrow-right"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
