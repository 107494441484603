import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Ratings from 'react-ratings-declarative';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import LoadingSpinner from "../ModalComponents/LoadingSpinner"

import { auth, firestore } from '../../Firebase';
import { calculateRating, loadingSpinner } from '../../App';

export default class VerifyUser extends Component {

  constructor(props){
    super(props);
    this.listener = null;
    this.state = {
      offset: 0,
      data: [],
      perPage: 6,
      currentPage: 0
    };
  }

  fetchData(status){
    switch (status) {
      case 1:
        document.getElementById("tripListActive").classList.add("active");
        document.getElementById("tripListHistorical").classList.remove("active");
        document.getElementById("tripListCancelled").classList.remove("active");
        break;
      case 2:
        document.getElementById("tripListActive").classList.remove("active");
        document.getElementById("tripListHistorical").classList.add("active");
        document.getElementById("tripListCancelled").classList.remove("active");
        break;
      case 3:
        document.getElementById("tripListActive").classList.remove("active");
        document.getElementById("tripListHistorical").classList.remove("active");
        document.getElementById("tripListCancelled").classList.add("active");
        break;
      default:
    }
    loadingSpinner(true);
    this.listener = firestore.collection('trips').where("status", "==", status).orderBy("departureDate").onSnapshot((querySelector) => {
      let tripList = [];
      querySelector.forEach((doc) => {
        var tripData = {
          tripId: doc.id,
          tripData: doc.data(),
        }
        tripList.push(tripData);
      });
      // this.setState({
      //   data: tripList
      // });
      this.setState({  data: tripList }, () => {
        this.paginateData();
        loadingSpinner(false);
      }); 
    });
  }

  componentDidMount(){
    this.fetchData(1);
    auth.onAuthStateChanged((user) => {
      if(!user && this.listener){
        this.listener();
      }
    });
  }

  paginateData(){
    const slice = this.state.data.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = slice.map((pd, key) => <React.Fragment key={key}>
      <div className="active-trip-card-container">
        <Link
          to={{
            pathname: `/TripDetails/${pd.tripId}`
          }}
          style={{textDecoration: 'none', color: "#505050", display: "flex", flexDirection: 'column'}}
        className="active-trip-card">
          <div className="trip-details-active-trips">
            <div className="depart-details">
              <div className="details-img">
                <i className="fas fa-plane-departure"></i>
              </div>
              <div className="info">
                <div className="info-content bold-title">Depature Details</div>
                <div className="info-content">{pd.tripData.departureAirport}</div>
                <div className="info-content">{pd.tripData.departureDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
            <div className="arrive-details">
              <div className="details-img">
                <i className="fas fa-plane-arrival"></i>
              </div>
              <div className="info">
                <div className="info-content bold-title">Arrival Details</div>
                <div className="info-content">{pd.tripData.arrivalAirport}</div>
                <div className="info-content">{pd.tripData.arrivalDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
          </div>
          <div className="traveller-details-container">
            <div className="traveller-details">
              <div className="details-img">
                {pd.tripData.travellerProfileImgUrl ?
                  <img
                    className="user-profile-image"
                    src={pd.tripData.travellerProfileImgUrl}
                    alt=""/>
                :
                  <i className="far fa-user-circle"></i>}
              </div>
              <div className="info">
                <div className="info-content bold-title">{pd.tripData.travellerDisplayName}</div>
                <div className="info-content">
                <Ratings
                  rating={calculateRating(pd.tripData.totalRating, pd.tripData.numberOfRatings)}
                  widgetRatedColors="rgb(0, 72, 108)"
                  widgetDimensions="16px"
                  widgetSpacings="2px">
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget /></Ratings></div>
                <div className="info-content">Listed {pd.tripData.creationDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(this.state.data.length / this.state.perPage),
      postData
    })
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.paginateData();
    });
  }

  render(){
    return(
      <div className="" style={{padding: '30px'}}>

        <Helmet>
          <title>Trippie Air | Current Active Trips</title>
          <meta name="description" content="Trippie Air | Current Active Trips" />
        </Helmet>
        <h1 className="control-title">
          Currently Active Trips
        </h1>
        <div className="active-trip-list">
          <div className="change-trip-display-container">
            <div className="change-buttons">
              <div id="tripListActive" className="change-trip-display-button noselect active" onClick={() => {this.fetchData(1)}}>Active</div>
              <div id="tripListHistorical" className="change-trip-display-button noselect" onClick={() => {this.fetchData(2)}}>Historic</div>
              <div id="tripListCancelled" className="change-trip-display-button noselect" onClick={() => {this.fetchData(3)}}>Cancelled</div>
            </div>
          </div>
          <div className="active-trip-container">
            <LoadingSpinner/>
            {this.state.postData}
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={<i className='fas fa-arrow-left'></i>}
                nextLabel={<i className="fas fa-arrow-right"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
