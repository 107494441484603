import React, {useContext} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {auth} from '../Firebase';

import Navigation from './Navigation/Navigation';
import Analytics from './DashboardComponents/Analytics';
import AuthTrip from './DashboardComponents/AuthNewTrips';
import AuthNewCompany from './DashboardComponents/AuthNewCompany';
import TripDetails from './DashboardComponents/TripDetails';
import CompanyDetails from './DashboardComponents/CompanyDetails';
import ActiveTrips from './DashboardComponents/ActiveTrips';
import UserProfile from './DashboardComponents/UserProfile';
import Notifications from './DashboardComponents/Notifications';
import UserManagement from './DashboardComponents/UserManagement';
import AdminManagement from './DashboardComponents/AdminManagement';
import CustomerProfile from './DashboardComponents/CustomerProfile';
import RequestDetails from './DashboardComponents/RequestDetails';
import ReportedTrips from './DashboardComponents/ReportedTrips';
import ReportedTripsDetails from './DashboardComponents/ReportedTripsDetails';
import CreateUser from './DashboardComponents/CreateUser';
import Earnings from './DashboardComponents/Earnings';
import Payments from './DashboardComponents/Payments';
import Faqs from './DashboardComponents/FAQs';
import EditFaq from './DashboardComponents/EditFaq';
import AddFaq from './DashboardComponents/AddFaq';
import OtherSettings from './DashboardComponents/OtherSettings';
import Delivery from './DashboardComponents/Delivery';
import DeliveryDetails from './DashboardComponents/DeliveryDetails';
import LogisticList from './DashboardComponents/LogisticList';
import LogisticEarnings from './DashboardComponents/LogisticEarning';
import {UserContext} from '../Providers/UserProvider';
import PaymentSuccess from './PaymentSuccess';
import ViewAdminUser from './DashboardComponents/view-admin-user';
import AdminUserProfile from "./DashboardComponents/admin-user-profile";
import {UserProfileWrapper} from "./DashboardComponents/user-profile-wrapper";

const Dashboard = () => {
    const {isThirdParty, isSuperAdmin} = useContext(UserContext);
    return (
        <>
            <div id="wrapper" className="wrapper">
                <Helmet>
                    <title>Trippie Air | Dashboard</title>
                    <meta name="description" content="Trippie Air | Dashboard"/>
                </Helmet>
                {/* <Router> */}
                <Navigation/>
                <Routes>
                    <Route path="/OtherSettings" element={<OtherSettings/>}/>
                    <Route path="/FAQs/create" element={<AddFaq/>}/>
                    <Route path="/payment-success" element={<PaymentSuccess/>}/>
                    <Route path="/FAQs/edit/:faqId" element={<EditFaq/>}/>
                    <Route exact="true" path="/FAQs" element={<Faqs/>}/>
                    <Route path="/Payments" element={<Payments/>}/>
                    <Route
                        path="/Earnings"
                        element={isThirdParty ? <LogisticEarnings/> : <Earnings/>}
                    />
                    {isSuperAdmin && <Route path="/AdminUsers/Create" element={<CreateUser/>}/>}
                    <Route
                        path="/ReportedListings/:listingId/:userId"
                        element={<ReportedTripsDetails/>}
                    />
                    <Route
                        exact="true"
                        path="/ReportedListings"
                        element={<ReportedTrips/>}
                    />
                    <Route
                        path="/Request/:userId/:dir/:requestId"
                        element={<RequestDetails/>}
                    />
                    <Route path="/AccountDetails/:userId" element={<CustomerProfile/>}/>
                    <Route path="/Customers" element={<UserManagement/>}/>
                    <Route path="/logistic-companies" element={<LogisticList/>}/>
                    {isSuperAdmin && <Route path="/AdminUsers" element={<AdminManagement/>}/>}
                    <Route path="/Notifications" element={<Notifications/>}/>
                    <Route path="/UserProfile" element={<UserProfileWrapper/>}/>
                    <Route path="/ActiveTrips" element={<Delivery/>}/>
                    <Route path="/CompanyDetails/:tripId" element={<CompanyDetails/>}/>
                    <Route
                        path="/TripDetails/:tripId/:userId"
                        element={<TripDetails/>}
                    />
                    <Route path="/AuthNewTrips" element={<AuthTrip/>}/>
                    <Route path="/AuthNewCompany" element={<AuthNewCompany/>}/>
                    <Route
                        path="/Delivery/:deliveryId/:userId"
                        element={<DeliveryDetails/>}
                    />
                    <Route exact="true" path="/" element={<Analytics/>}/>
                    <Route path="/ViewAdminUser/:userId" element={<ViewAdminUser/>}/>
                </Routes>
                {/* </Router> */}
            </div>
            <div className="footer" id="footer">
                <div className="copyright">
                    <span className="copyright-bold">Copyright </span>Trippie Limited ©
                    2024
                </div>
                <div className="version">
                    <div className="float-right">
                        Version&nbsp;<span className="copyright-bold">0.1.42</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
