import React, { Component } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { Helmet } from 'react-helmet';

import { firestore } from '../../Firebase';
import { spinner, showConfirmDialog } from "../../App"

import Spinner from '../ModalComponents/Spinner'

export default class EditFaq extends Component{
  constructor(props){
    super(props)
    this.state = {
      type: 'general',
      title: '',
      content: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  validateFaq(){
    let text_regex = /^[a-zA-Z0-9.,!&\-\\? ]*$/;
    let typeError = document.getElementById("typeError");
    let titleError = document.getElementById("titleError");
    let richError = document.getElementById("richError");

    if (this.state.type === '') {
      typeError.style.display = 'block'
      typeError.innerHTML = "Error: The type field contains characters or emojis that are not allowed."
      return false;
    }else {
      typeError.style.display = 'none'
    }
    if(this.state.title === '' || !text_regex.test(this.state.title)){
      titleError.style.display = 'block'
      titleError.innerHTML = "Error: The title field contains characters or emojis that are not allowed."
      return false;
    }else {
      titleError.style.display = 'none'
    }
    if(this.state.content === ''){
      richError.style.display = 'block'
      richError.innerHTML = "Error: The content field contains characters or emojis that are not allowed."
      return false;
    }else{
      richError.style.display = 'none'
    }
    return true;
  }

  saveFaq(){
    if(!this.validateFaq()) return false;
    let faqData = {
      type: this.state.type,
      title: this.state.title,
      content: this.state.content
    }

    let addFaqRichTextEditor = document.getElementById('addRichTextEditor');

    firestore.collection('faqs').add(faqData).then(() => {
      spinner(true);
      showConfirmDialog(false, `FAQ Succesfully added to database!`);
      this.setState({
        type: 'general',
        title: '',
        content: ''
      })
      addFaqRichTextEditor.setContent('');
    }).catch((error) => {
      console.log('An errr occured');
      // spinner(true);
      // showConfirmDialog(true, `An error occured: ${error}`);
    })
  }

  handleInputChange(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  handleEditorChange(e){
    this.setState({content: e.target.getContent()});
  }

  render(){
    return(
      <div style={{padding: "30px"}}>
        <Helmet>
          <title>Trippie Air | Create new FAQ</title>
          <meta name="description" content="Trippie Air | User Profiles" />
        </Helmet>
        <Spinner/>
        <div>
          <h1 className="control-title">
            Create a new FAQ
          </h1>
          <div className="edit-faq-container">
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Type:
              </div>
              <div className="user-data-content-info">
                <select
                className="user-data-content-info-input flex-column"
                name="type"
                value={this.state.type}
                onChange={this.handleInputChange}>
                  <option value={'general'}>General</option>
                  <option value={'sender'}>Sender</option>
                  <option value={'traveller'}>Traveller</option>
                </select>
                <div id="typeError" className="ban-error-message"></div>
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Title:
              </div>
              <div className="user-data-content-info flex-column">
                <input
                className="user-data-content-info-input"
                name="title"
                value={this.state.title}
                onChange={this.handleInputChange}/>
                <div id="titleError" className="ban-error-message"></div>
              </div>
            </div>
            <div className="user-data-content">
              <div className="user-data-content-info bold-title">
                Content:
              </div>
              <div className="user-data-content-info editor-container flex-column">
                <Editor
                  id="addRichTextEditor"
                  apiKey="xdpqo1c8k86yigxjmv90hb0jxkuanquoldiegd6bfqg2y5nn"
                  init={{
                    height: 400,
                    menubar: false,
                    toolbar:
                      'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent | help'
                  }}
                  onChange={this.handleEditorChange}
                />
                <div id="richError" className="ban-error-message"></div>
              </div>
            </div>
            {this.state.content}
            <div className="action-buttons-container align-bottom">
              <div onClick={() => this.saveFaq()} className="user-action-button save-user-btn noselect">Save</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
