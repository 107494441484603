import React from 'react';
import LoadingSpinner from './ModalComponents/LoadingSpinner';

const Loader = ({ show }) => {
  return (
    <div className={`loader ${show}`}>
      <LoadingSpinner />
    </div>
  );
};

export default Loader;
