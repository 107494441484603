import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { auth, firestore } from '../../Firebase';
import { getCompanyStatus } from '../../utils/helper';
import { Form } from 'react-bootstrap';

export default class LogisticList extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      data: [],
      perPage: 10,
      currentPage: 0,
      offset: 0,
      search: '',
    };
  }

  fetchData() {
    this.listener = firestore
      .collection('companies')
      .where('isEmailSend', '==', true)
      .orderBy('lastUpdated', 'desc')
      .onSnapshot((querySelector) => {
        let tripList = [];
        querySelector.forEach((doc) => {
          var tripData = {
            tripId: doc.id,
            tripData: doc.data(),
          };
          tripList.push(tripData);
        });
        if (this.state.search) {
          tripList = tripList.filter((trip) =>
            trip.tripData.companyName
              .toLowerCase()
              .includes(this.state.search.toLowerCase())
          );
        }
        this.setState({ data: tripList }, () => {
          this.paginateData(tripList);
        });
      });
  }

  componentDidMount() {
    this.fetchData();
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.state.search !== prevState.search) {
      this.fetchData();
    }
  }

  paginateData(tripList = null) {
    const slice = this.state.data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    console.log(slice, 'slice ----');
    const postData = slice.map((pd, key) => (
      <React.Fragment key={key}>
        <Link
          to={{
            pathname: `/CompanyDetails/${pd.tripId}`,
          }}
          style={{ textDecoration: 'none', color: '#505050' }}
          className="trip-list-item-line"
        >
          <div className="trip-list-item-entry">{pd.tripData.companyName}</div>
          <div className="trip-list-item-entry">
            {pd.tripData.street || '-'}
          </div>
          <div className="trip-list-item-entry">{pd.tripData.address}</div>
          <div className="trip-list-item-entry">{pd.tripData.phoneNumber}</div>
          <div className="trip-list-item-entry">
            {pd.tripData.contactPersonName}
          </div>
          <div className="trip-list-item-entry">
            {pd.tripData.contactPersonJobTitle}
          </div>
          <div className="trip-list-item-entry">
            {pd.tripData.contactPersonPhoneNumber}
          </div>
          <div className="trip-list-item-entry">
            {getCompanyStatus(pd.tripData.verified)}
          </div>
          <div className="trip-list-item-entry">{pd.tripData.companyCode}</div>
          <div className="trip-list-item-entry">
            {pd.tripData.contactPersonEmail}
          </div>
          <div className="trip-list-item-entry">
            <a href="{pd.tripData.commercialLicenseUrl}">Download</a>
          </div>
        </Link>
      </React.Fragment>
    ));
    this.setState({
      pageCount: Math.ceil(this.state.data.length / this.state.perPage),
      postData,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.paginateData();
      }
    );
  };

  render() {
    return (
      <div className="" style={{ padding: '30px' }}>
        <Helmet>
          <title>Trippie Air | Authorise new Company</title>
          <meta
            name="description"
            content="Trippie Air | Authorise new trips"
          />
        </Helmet>
        <div className="flex">
          <h1 className="control-title">Logistic companies</h1>
          <Form.Control
            type="text"
            value={this.state.search}
            placeholder="Search Companies by Name"
            className="search-user"
            onChange={(e) => this.setState({ search: e.target.value })}
          />
        </div>

        <div className="trip-list">
          <div className="trip-list-table">
            <div className="trip-list-table-head">
              <div className="trip-list-table-head-title">Name</div>
              <div className="trip-list-table-head-title">Address 1</div>
              <div className="trip-list-table-head-title">Address 2</div>
              <div className="trip-list-table-head-title">Phone</div>
              <div className="trip-list-table-head-title">
                Contact Person Name
              </div>
              <div className="trip-list-table-head-title">
                Contact Person Job Title
              </div>
              <div className="trip-list-table-head-title">
                Contact Person Phone Number
              </div>
              <div className="trip-list-table-head-title">Verification</div>
              <div className="trip-list-table-head-title">Company Code</div>
              <div className="trip-list-table-head-title">
                Contact Person Phone Email
              </div>
              <div className="trip-list-table-head-title">
                Commercial License
              </div>
            </div>
            <div className="trip-list-table-body">
              <div
                className="trip-list-table-item"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {this.state.postData}
              </div>
            </div>
          </div>
          <div className="pagination-container no-shadow">
            <ReactPaginate
              previousLabel={<i className="fas fa-arrow-left"></i>}
              nextLabel={<i className="fas fa-arrow-right"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    );
  }
}
