import React from 'react';

export default function LoadingSpinner(){
  return(
    <div id="loadingSpinner" className="loading-spinner-background">
      <div className="this-that">
        <div className="spinner loading">
        </div>
      </div>
    </div>
  )
}
