import firebase from 'firebase/compat/app'; // Import the Firebase SDK
import 'firebase/compat/firestore'; // Import Firestore
import 'firebase/compat/auth'; // Import Auth
import 'firebase/compat/storage'; // Import Storage
import 'firebase/compat/functions'; // Import functions

const firebaseConfig = {
    apiKey: "AIzaSyALvmWCFGLQpL6wxgN0TIHCq9kLlVR3WJQ",
    authDomain: "trippie-nz-flutter.firebaseapp.com",
    projectId: "trippie-nz-flutter",
    storageBucket: "trippie-nz-flutter.firebasestorage.app",
    messagingSenderId: "525681664216",
    appId: "1:525681664216:web:b7a02db9c41d6cf60c37ad",
    measurementId: "G-LET2GEGF00"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

const app = firebase.app();
const functions = app.functions('asia-northeast1');
// const functions = getFunctions(firebaseConfig);

const FieldValue = firebase.firestore.FieldValue;
export {firestore, auth, storage, functions, firebase, FieldValue};
