import {useState} from "react";
import {Dialog, DialogActions, DialogContentText, DialogTitle, Stack, TextField} from "@mui/material";
import Button from "react-bootstrap/Button";

export const DeliveryCancelDialog = ({isOpen, onRequestClose, onConfirm}) => {
    const [cancelReason, setCancelReason] = useState("");
    return <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>
            Cancel Delivery
        </DialogTitle>
        <Stack spacing={2} m={2}>
            <TextField label="Reason"
                       multiline={true}
                       maxRows={10}
                       fullWidth
                       minRows={3}
                       value={cancelReason}
                       onChange={(e) => setCancelReason(e.target.value)}/>

            <DialogContentText>
                Are you sure you want to cancel this delivery? Please provide a reason for cancellation.
            </DialogContentText>
        </Stack>
        <DialogActions>
            <Button variant="secondary" onClick={onRequestClose}>Close</Button>
            <Button variant="primary" onClick={() => {
                onConfirm(cancelReason);
                onRequestClose();
            }}>Confirm</Button>
        </DialogActions>
    </Dialog>
}