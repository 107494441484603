import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { BASEURL } from "../constants";

const PaymentForm = ({ onClose, userId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const PAYMENT_SUCCESS_URL = `${BASEURL}/payment-success?userId=${userId}`;
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    setMessage("Payment in Progress");

    const resp = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: PAYMENT_SUCCESS_URL,
      },
      redirect: "if_required",
    });

    if (resp.error) {
      setMessage(`Some Error Occurred !! ${resp.error.message}`);
    }else{
      setMessage("Payment Success !!");
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit}>
        <div className="card w-100 bg-base-100 bg-gray-200 shadow-2xl rounded-lg">
          <div className="card-body p-6">
            <PaymentElement />
            <div className="card-actions justify-center">
              <button
                className="btn btn-primary rounded-xl text-white px-4 py-2 mt-6"
                style={{ marginTop: "10px" }}
                disabled={isLoading || !stripe || !elements}
              >
                {isLoading ? "Loading..." : "Pay now"}
              </button>
              <button
                className="btn btn-primary rounded-xl text-white px-4 py-2 mt-6"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
            {message && <div>{message}</div>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
