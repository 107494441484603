import { firestore } from '../Firebase';
import { assignNextStatus, haveValue } from '../utils/helper';
import { getUserById } from './user-services';

const updateDeliveryRequest = async (
  userId,
  deliveryId,
  status,
  driverId,
  driverDetails
) => {
  try {
    await firestore
      .collection(`delivery_requests/${userId}/requests_stored`)
      .doc(deliveryId)
      .update({
        status: status,
        [`${status === 'pickup' ? 'pickup_driver' : 'dropOff_driver'}`]:
          driverId,
        [`${
          status === 'pickup'
            ? 'pickup_driver_details'
            : 'dropOff_driver_details'
        }`]: driverDetails,
      });
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document:', error);
    throw error;
  }
};

//** assign driver for pickup and dropofff  */

export const assignDriver = async (deliveryId, userId, payload) => {
  try {
    if (!haveValue(deliveryId)) {
      throw new Error('Invalid delivery ID');
    }

    const driveInfo = await getUserById(payload.driverId);

    if (!driveInfo) {
      throw new Error('Invalid driver information');
    }

    const nextStatus = assignNextStatus(payload.status);
    const driverDetails = { ...driveInfo, assignedTask: nextStatus };

    if (nextStatus === 'pickup' || nextStatus === 'on_the_way') {
      await updateDeliveryRequest(
        userId,
        deliveryId,
        nextStatus,
        payload.driverId,
        driverDetails
      );
    } else {
      throw new Error('Invalid status');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDeliveryRequestInfo = async (collection, requestId) => {
  if (!collection || !requestId) {
    console.error('Invalid input: collection and requestId are required');
    return null;
  }

  try {
    const docRef = firestore.collection(collection).doc(requestId);
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      return {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      };
    } else {
      console.log(
        `No document found with ID: ${requestId} in collection: ${collection}`
      );
      return null;
    }
  } catch (error) {
    console.error(`Error fetching document from ${collection}:`, error);
    throw error; // Re-throw the error for the caller to handle if needed
  }
};
