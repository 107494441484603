import React, { useState, useEffect, useContext, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import { auth, firestore } from '../../Firebase';
import { UserContext } from '../../Providers/UserProvider';
import { companyInfoById } from '../../services/logistic-services';
import { haveValue, stripePortion } from '../../utils/helper';
import Select from 'react-select';
import { fetchCompanies } from '../../services/user-services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Earnings = () => {
  const userContext = useContext(UserContext);
  const [transferData, setTransferData] = useState([]);
  const [profit, setProfit] = useState(0);
  const [completedTransfers, setCompletedTransfers] = useState(0);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [companyOptions, selectedCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([
    { label: 'All', value: null },
    { label: 'Card', value: 'card' },
    { label: 'Wallet', value: 'wallet' },
    { label: 'COD', value: 'cod' },
  ]);
  const [selectedPayment, setSelectPayment] = useState(null);
  let listener = null;

  useEffect(() => {
    const fetchTransferData = async () => {
      let query = firestore
        .collection('connect_transfers')
        .orderBy('created', 'desc');

      if (userContext.isThirdParty) {
        query = query.where('companyId', '==', userContext.admin.company);
      }
      console.log(selectedCompany, 'selectedCompany');
      if (selectedCompany && haveValue(selectedCompany.value)) {
        if (selectedCompany.value === 'logistics') {
          query = query.where('companyId', 'not-in', ['null', '']);
        } else {
          query = query.where('companyId', '==', selectedCompany.value);
        }
      }
      if (selectedPayment && selectedPayment.value !== null) {
        query = query.where('paymentType', '==', selectedPayment.value);
      }

      query.onSnapshot(async (docSnap) => {
        let transfersList = [];
        let totalProfit = 0;
        let transferTotal = 0;

        const transfers = docSnap.docs.map(async (doc) => {
          const transferData = {
            transferId: doc.id,
            transferData: doc.data(),
          };

          const companyInfo = await companyInfoById(
            transferData.transferData.companyId
          );
          const transfer = doc.data();
          if (haveValue(doc.data().companyId)) {
            totalProfit +=
              transfer.deliveryRequest.paidAmount / 100 -
              transfer.transferAmount / 100;
          } else {
            totalProfit += transfer.deliveryRequest.paidAmount / 100;
          }

          transferTotal++;
          return { ...transferData, companyInfo };
        });

        transfersList = await Promise.all(transfers);
        setProfit(totalProfit);
        setTransferData(transfersList);
        setCompletedTransfers(transferTotal);
      });
    };

    fetchTransferData();

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user && listener) {
        listener();
      }
    });

    return () => {
      if (listener) {
        listener();
      }
      unsubscribe();
    };
  }, [userContext, selectedCompany, selectedPayment]);

  const handleSelect = (select) => {
    setSelectedCompany(select);
  };

  const DisplayTransferData = useCallback(() => {
    const slice = transferData.slice(offset, offset + perPage);
    setPageCount(Math.ceil(transferData.length / perPage));
    return (
      <DataTable value={slice} stripedRows tableStyle={{ minWidth: '50rem' }}>
        <Column
          field="transferData.requestId"
          header="Request Id"
          body={(rowData) => rowData.transferData.requestId.split('_')[0]}
        ></Column>
        <Column
          field="transferData.created"
          header="Created Date"
          body={(rowData) => {
            return rowData.transferData.created
              .toDate()
              .toLocaleString('en-NZ');
          }}
        ></Column>
        <Column
          field="transferData.deliveryRequest.paymentType"
          header="Payment Type"
          body={(rowData) =>
            rowData.transferData.deliveryRequest.paymentType.toUpperCase()
          }
        ></Column>
        <Column
          field="transferData.offerAmount"
          header="Offer Amount"
          body={(rowData) => `$${rowData.transferData.offerAmount.toFixed(2)}`}
        ></Column>
        <Column
          field="companyInfo.companyName"
          header="Receiver"
          body={(rowData) => rowData.companyInfo?.companyName || 'Trippie Air'}
        ></Column>
        <Column
          field="transferData.offerAmount"
          header="Stripe Portion"
          body={(rowData) =>
            `$${stripePortion(rowData.transferData.offerAmount)}`
          }
        ></Column>
        <Column
          field="transferData.transferAmount"
          header="Transfer Amount"
          body={(rowData) =>
            rowData.companyInfo
              ? `$${(rowData.transferData.transferAmount / 100).toFixed(2)}`
              : '$0'
          }
        ></Column>
        <Column
          field="transferData.deliveryRequest.paidAmount"
          header="Paid Amount"
          body={(rowData) =>
            `$${(rowData.transferData.deliveryRequest.paidAmount / 100).toFixed(
              2
            )}`
          }
        ></Column>
        <Column
          field="transferData.failed"
          header="Status"
          body={(rowData) =>
            rowData.transferData.failed ? 'Failed' : 'Success'
          }
        ></Column>
      </DataTable>
    );
  }, [transferData, offset, perPage, selectedCompany]);

  useEffect(() => {
    // displayTransferData();
    fetchCompanies()
      .then((cmplist) => {
        let options = [
          {
            label: 'All',
            value: 0,
          },
          {
            label: 'Trippie Air',
            value: 'null',
          },
          { label: 'Logistics Companies', value: 'logistics' },
        ];
        cmplist.forEach((dc) => {
          const obj = {};
          if (haveValue(dc)) {
            obj.label = dc.data.companyName;
            obj.value = dc.id;
          }
          options.push(obj);
        });
        selectedCompanyOptions(options);
      })
      .catch((err) => console.error('Error while fetch Companies', err));
  }, [transferData, offset, perPage]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(offset);
  };

  return (
    <div style={{ padding: '30px' }}>
      <Helmet>
        <title>Trippie Air | Financials</title>
      </Helmet>
      <div>
        <div className="assign-drive">
          <h1 className="control-title">Earnings</h1>

          <div className="select-box">
            {!userContext.isThirdParty && (
              <Select
                defaultValue={selectedCompany}
                isSearchable={true}
                isClearable={true}
                onChange={handleSelect}
                placeholder="Select Company"
                options={companyOptions}
              />
            )}

            <Select
              isSearchable={true}
              isClearable={true}
              defaultValue={selectedPayment}
              onChange={(select) => setSelectPayment(select)}
              placeholder="Select Payment type"
              options={paymentTypeOptions}
            />
          </div>
        </div>
        <div className="profit-box noselect">
          <div className="total-transfers w50 margin7right">
            <div className="box-info">
              <div className="box-info-number">
                <CountUp end={completedTransfers} decimals={0} />
              </div>
              <div className="box-info-desc">Total completed deliveries</div>
            </div>
            <div className="box-img">
              <i class="fa fa-car"></i>
            </div>
          </div>
          <div className="total-profit w50 margin7left">
            <div className="box-info">
              <div className="box-info-number">
                <CountUp end={profit} decimals={2} prefix="$" suffix=" NZD" />
              </div>
              <div className="box-info-desc">Total earning</div>
            </div>
            <div className="box-img">
              <i className="fas fa-money-check"></i>
            </div>
          </div>
        </div>
        <div className="notifications-content">
          <div className="notifications-list-table">
            {/* <div className="notifications-list-header">
              <div className="notifications-list-header-title w30">
                Delivery Id
              </div>
              <div className="notifications-list-header-title w30">
                Transfer Created
              </div>
              <div className="notifications-list-header-title w30">
                Payment Type
              </div>
              <div className="notifications-list-header-title w20">
                Paid Amount
              </div>
              <div className="notifications-list-header-title w20">
                Log Company
              </div>
              <div className="notifications-list-header-title w20">
                Stripe Portion
              </div>
              <div className="notifications-list-header-title w20">
                Logistic Portion
              </div>
              <div className="notifications-list-header-title w20">
                Net Total
              </div>
              <div className="notifications-list-header-title w10">Status</div>
            </div>
            {postData} */}
            <DisplayTransferData />
          </div>
          <div className="pagination-container no-shadow">
            <ReactPaginate
              previousLabel={<i className="fas fa-arrow-left"></i>}
              nextLabel={<i className="fas fa-arrow-right"></i>}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
