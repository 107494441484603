import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { functions } from '../../Firebase';
import { showConfirmDialog, spinner } from '../../App';
import { Col, Form, Row } from 'react-bootstrap';

const BanModelCompanies = ({
  openModel,
  setOpenModel,
  companyId,
  companyData,
}) => {
  const initState = {
    companyId: companyId,
    banReason: '',
    banLengthSelector: 0,
    banTimeNumber: 1,
    banTimeSelector: 86400000,
    validate: true,
  };
  const [banForm, setBannedForm] = useState(initState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log({ name, value });
    setBannedForm((prev) => ({ ...prev, [name]: value }));
  }

  console.log('banForm', banForm);

  const handleSubmitBan = () => {
    setIsSubmitting(true);
    var length =
      banForm.banTimeNumber *
      banForm.banTimeSelector *
      banForm.banLengthSelector;
    var disableUserAccount = functions.httpsCallable('disableCompanyAccount');
    let data = {
      companyId: banForm.companyId,
      reason: banForm.banReason,
      length: length,
    };
    spinner(true);
    disableUserAccount(data)
      .then(() => {
        showConfirmDialog(false, 'The ban hammer has spoken.');
        setBannedForm(initState);
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
        setOpenModel(false);
      });
  };

  const handSubmitUnBan = () => {
    setIsSubmitting(true);
    var disableUserAccount = functions.httpsCallable('enableCompaniesAccount');
    let data = {
      companyId: banForm.companyId,
    };
    spinner(true);
    disableUserAccount(data)
      .then(() => {
        showConfirmDialog(false, 'The ban hammer has spoken.');
        setBannedForm(initState);
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
        setOpenModel(false);
      });
  };

  const handleClose = () => setOpenModel(false);
  return (
    <>
      <Modal show={openModel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            {companyData.isBanned
              ? 'Are you sure you want to Unban'
              : 'Select the reason and length for ban.'}{' '}
          </Modal.Title>
        </Modal.Header>
        {!companyData.isBanned && (
          <Modal.Body>
            <div className="modal-ban-user-content">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Ban Reason</Form.Label>
                <Form.Control
                  type="text"
                  className="user-data-content-info-input"
                  name="banReason"
                  value={banForm.banReason}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Ban Type</Form.Label>
                <Form.Select
                  className="user-data-content-info-input"
                  name="banLengthSelector"
                  value={banForm.banLengthSelector}
                  onChange={handleInputChange}
                >
                  <option value={0}>Indefinately</option>
                  <option value={1}>Timed Ban</option>
                </Form.Select>
              </Form.Group>

              {banForm.banLengthSelector === '1' && (
                <Row>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Ban Length</Form.Label>
                    <Col className="d-flex">
                      <Form.Control
                        type="number"
                        className="user-data-content-info-input small-input-10"
                        name="banTimeNumber"
                        value={banForm.banTimeNumber}
                        onChange={handleInputChange}
                        min="1"
                        max="99"
                      />
                      <Form.Select
                        className="user-data-content-info-input large-input-90"
                        name="banTimeSelector"
                        value={banForm.banTimeSelector}
                        onChange={handleInputChange}
                      >
                        <option value={86400000}>Day(s)</option>
                        <option value={2592000000}>Month(s)</option>
                        <option value={31556952000}>Year(s)</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Row>
              )}
            </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <div
            onClick={() => handleClose(false)}
            className="user-action-button ban-user-btn noselect"
          >
            Cancel
          </div>
          {console.log('isSubmitting', isSubmitting)}

          {companyData.isBanned ? (
            <button
              onClick={handSubmitUnBan}
              type="button"
              className="user-action-button save-user-btn noselect"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Unbanning...' : ' Unban'}
            </button>
          ) : (
            <button
              onClick={handleSubmitBan}
              type="button"
              className="user-action-button save-user-btn noselect"
              disabled={isSubmitting}
              aria-disabled={isSubmitting}
            >
              {isSubmitting ? 'Banning...' : 'Confirm Ban'}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BanModelCompanies;
