import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from '../../withRouter';
import { Helmet } from 'react-helmet';
import Ratings from 'react-ratings-declarative';

import { spinner, showConfirmDialog, calculateRating } from "../../App"
import { functions, firestore } from '../../Firebase'

import Spinner from '../ModalComponents/Spinner'
import ShimmerLoader from '../ModalComponents/ShimmerLoading'

class ReportedTripsDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.match.params.userId,
      listingId: this.props.match.params.listingId,
      reportData: null,
      tripData: null,
      reporterDetails: null
    }
  }

  fetchReportData(listingId, userId) {
    let col = `listing_reports/${listingId}/reports/`;
    firestore.collection(col).doc(userId).get().then((docSnap) => {
      if (docSnap.exists) {
        this.setState({
          reportData: docSnap.data()
        })
        this.fetchReporterUserProfile(this.state.reportData.reporterId);
      } else {
        spinner(true);
        showConfirmDialog(true, "This report no longer exists!");
      }
    });
  }

  fetchReporterUserProfile(userId) {
    firestore.collection('user_public').doc(userId).get().then((docSnap) => {
      if (docSnap.exists) {
        this.setState({
          reporterDetails: docSnap.data()
        })
      } else {
        spinner(true);
        showConfirmDialog(true, "The user who made this report could not be found!");
      }
    })
  }

  fetchTripData(listingId) {
    firestore.collection('trips').doc(listingId).get().then((docSnap) => {
      if (docSnap.exists) {
        this.setState({
          tripData:
          {
            id: docSnap.id,
            data: docSnap.data()
          }
        })
      } else {
        spinner(true);
        showConfirmDialog(true, "This trip no longer exists!");
      }
    })
  }

  componentDidMount() {
    this.fetchReportData(this.state.listingId, this.state.userId);
    this.fetchTripData(this.state.listingId);
  }

  displayReportInfo() {
    if (!this.state.reporterDetails) {
      return (
        <ShimmerLoader />
      )
    } else {
      return (
        <div className="report-info-card">
          <h1 className="report-header">Details on the Report</h1>
          <div className="report-details-content">
            <div className="report-details-card">
              <div className="report-items">
                <div className="report-items-title w20">
                  Listing ID
                </div>
                <div className="report-items-description w80">
                  {this.state.reportData.listingId}
                </div>
              </div>
              <div className="report-items">
                <div className="report-items-title w20">
                  Listing Type
                </div>
                <div className="report-items-description w80">
                  {this.state.reportData.listingType}
                </div>
              </div>
              <div className="report-items">
                <div className="report-items-title w20">
                  Report Reason
                </div>
                <div className="report-items-description w80">
                  {this.state.reportData.reportReason}
                </div>
              </div>
              <div className="report-items">
                <div className="report-items-title w20">
                  Date and Time of Report
                </div>
                <div className="report-items-description w78">
                  {this.state.reportData.reportTime.toDate().toLocaleString('en-NZ')}
                </div>
              </div>
            </div>
            <Link
              to={{
                pathname: `/AccountDetails/${this.state.reportData.reporterId}`
              }}
              style={{ textDecoration: 'none', color: "#505050", display: "flex", flexDirection: 'column' }}
              className="reporter-user-profile-card">
              <div className="traveller-details-container">
                <div className="traveller-details">
                  <div className="details-img">
                    {this.state.reporterDetails.profileImgUrl ?
                      <img
                        className="user-profile-image"
                        src={this.state.reporterDetails.profileImgUrl} />
                      :
                      <i className="far fa-user-circle"></i>}
                  </div>
                  <div className="info">
                    <div className="info-content bold-title">{this.state.reporterDetails.displayName}</div>
                    <div className="info-content">
                      <Ratings
                        rating={calculateRating(this.state.reporterDetails.totalRating, this.state.reporterDetails.numberOfRatings)}
                        widgetRatedColors="rgb(0, 72, 108)"
                        widgetDimensions="16px"
                        widgetSpacings="2px">
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget /></Ratings></div>
                    <div className="info-content">Bio: {this.state.reporterDetails.aboutMe}</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )
    }
  }

  displayTripInfo() {
    if (!this.state.tripData) {
      return (
        <ShimmerLoader />
      )
    } else {
      return (
        <Link
          to={{
            pathname: `/TripDetails/${this.state.tripData.id}`
          }}
          style={{ textDecoration: 'none', display: "flex", flexDirection: 'column' }}
          className="report-info-card trip-details">
          <h1 className="report-header">Reported Trip</h1>
          <div className="report-trip-details-active-trips">
            <div className="depart-details">
              <div className="details-img">
                <i className="fas fa-plane-departure"></i>
              </div>
              <div className="info">
                <div className="info-content bold-title">Depature Details</div>
                <div className="info-content">{this.state.tripData.data.departureAirport}</div>
                <div className="info-content">{this.state.tripData.data.departureDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
            <div className="arrive-details">
              <div className="details-img">
                <i className="fas fa-plane-arrival"></i>
              </div>
              <div className="info">
                <div className="info-content bold-title">Arrival Details</div>
                <div className="info-content">{this.state.tripData.data.arrivalAirport}</div>
                <div className="info-content">{this.state.tripData.data.arrivalDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className="traveller-details-container">
            <div className="traveller-details">
              <div className="details-img">
                {this.state.tripData.data.travellerProfileImgUrl ?
                  <img
                    className="user-profile-image"
                    src={this.state.tripData.data.travellerProfileImgUrl} />
                  :
                  <i className="far fa-user-circle"></i>}
              </div>
              <div className="info">
                <div className="info-content bold-title">{this.state.tripData.data.travellerDisplayName}</div>
                <div className="info-content">
                  <Ratings
                    rating={calculateRating(this.state.tripData.data.totalRating, this.state.tripData.data.numberOfRatings)}
                    widgetRatedColors="rgb(0, 72, 108)"
                    widgetDimensions="16px"
                    widgetSpacings="2px">
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget /></Ratings></div>
                <div className="info-content">Listed {this.state.tripData.data.creationDate.toDate().toLocaleString("en-NZ")}</div>
              </div>
            </div>
          </div>
        </Link>
      )
    }
  }

  render() {
    return (
      <div style={{ padding: "30px" }}>
        <Spinner />
        <Helmet>
          <title>Trippie Air | Reported Trips</title>
          <meta name="description" content="Trippie Air | User Profiles" />
        </Helmet>
        <div>
          <h1 className="control-title">
            Detailed Report
          </h1>
          <div className="report-content">
            {this.displayReportInfo()}
            {this.displayTripInfo()}
            <div className="action-buttons-container">
              <div className="user-action-button ban-user-btn">
                SOMETHING
              </div>
              <div className="user-action-button">
                SOMETHING ELSE
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ReportedTripsDetails);
