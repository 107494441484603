import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom'
import { withRouter } from '../../withRouter';

import { handleOnlineUserModal, spinner, showConfirmDialog } from '../../App';
import { functions } from '../../Firebase';

class ModalOnlinerUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: window.location.href.split('/').at(-1),
      validate: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnlineSubmit = this.handleOnlineSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  validateForm() {

    return true;
  }

  handleOnlineSubmit() {
    if (!this.validateForm()) return;

    var changeUserOnlineStatus = functions.httpsCallable('changeUserOnlineStatus');
    let data = {
      userId: this.state.userId,
    };
    spinner(true);
    changeUserOnlineStatus(data)
      .then((data) => {
        showConfirmDialog(false, 'The online status changed.');
        handleOnlineUserModal(false);
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
        handleOnlineUserModal(false);
      });
  }

  render() {
    return (
      <div
        id="onlineUserModal"
        className="modal-ban-user-background hide-ban-user"
      >
        <div className="modal-ban-user-container" style={{'height':'100px'}}>
        <div className="modal-ban-user-content">

          <div className="action-buttons-container align-center">
            <div
              onClick={() => handleOnlineUserModal(false)}
              className="user-action-button ban-user-btn noselect"
            >
              Cancel
            </div>
            <div
              onClick={this.handleOnlineSubmit}
              className="user-action-button save-user-btn noselect"
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
      </div >
    );
  }
}

export default withRouter(ModalOnlinerUser);
