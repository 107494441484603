import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PaymentSuccess = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const redirectStatus = query.get('redirect_status');
  const userId = query.get('userId');

  useEffect(() => {
    if (redirectStatus === 'ucceeded') {
      //   return navigate(`/AccountDetails/${userId}`);
    }
  }, [redirectStatus]);

  const redirect = () => {
    return navigate(`/AccountDetails/${userId}`);
  };

  return (
    <div>
      <div className="wallet-topup">
        {redirectStatus === 'succeeded' ? (
          <>
            <p className="success">Wallet Topup Successfully</p>
            <button
              className="btn btn-primary rounded-xl text-white px-4 py-2 mt-6"
              onClick={redirect}
            >
              Go to Account page
            </button>
          </>
        ) : (
          <p className="error">Something Wrong</p>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
