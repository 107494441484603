import React, { Component } from 'react';
import Ratings from 'react-ratings-declarative';
import { Helmet } from 'react-helmet';
// import { withRouter } from 'react-router-dom'
import { withRouter } from '../../withRouter';

import { spinner, showConfirmDialog, calculateRating } from '../../App';
import { auth, firestore } from '../../Firebase';

import ProgressionBar from '../ModalComponents/ProgressionBar';
import Spinner from '../ModalComponents/Spinner';
import ShimmerLoader from '../ModalComponents/ShimmerLoading';

class RequestDetails extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.subscribe = null;
    this.state = {
      userId: this.props.match.params.userId,
      dir: this.props.match.params.dir,
      requestId: this.props.match.params.requestId,
      requestData: null,
      userData: null,
    };
    console.log(this.props, 'props');
  }

  fetchRequest(userId, dir, requestId) {
    this.listener = firestore
      .collection(`delivery_requests/${userId}/requests_${dir.toLowerCase()}`)
      .doc(requestId)
      .onSnapshot((docSnap) => {
        if (docSnap.exists) {
          this.setState({
            requestData: docSnap.data(),
          });
        } else {
          spinner(true);
          showConfirmDialog(true, 'This request no longer exists!');
        }
        this.fetchTravellerDetails(this.state.requestData.travellerId);
      });
  }

  fetchTravellerDetails(travellerId) {
    this.subscribe = firestore
      .collection('user_public')
      .doc(travellerId)
      .onSnapshot((docSnap) => {
        if (docSnap.exists) {
          this.setState({
            userData: docSnap.data(),
          });
        } else {
          spinner(true);
          showConfirmDialog(true, 'Could not find user data!');
        }
      });
  }

  displayProgressionBar() {
    if (!this.state.userData) {
      return <ShimmerLoader />;
    } else if (this.state.requestData.status == 7) {
      return (
        <div className="request-item-info-card">
          <div className="progression-bar-container">
            <div className="details-title">
              <div className="title-content">Status</div>
            </div>
            This request has been cancelled.
          </div>
        </div>
      );
    } else {
      return (
        <div className="request-item-info-card">
          <div className="progression-bar-container">
            <div className="details-title">
              <div className="title-content">Status</div>
            </div>
            <ProgressionBar
              status={this.state.requestData.status}
              lastUpdated={this.state.requestData.lastUpdated}
            />
          </div>
        </div>
      );
    }
  }

  displayRequestInfo() {
    if (!this.state.requestData) {
      return <ShimmerLoader />;
    } else {
      return (
        <div className="request-item-info-card">
          <div className="item-info-image-container w20">
            <img
              className="item-info-image"
              src={this.state.requestData.deliveryRequest.imageUri}
              alt="ImageUnavailable"
            />
          </div>
          <div className="item-info-content-container w50">
            <div className="item-info-content-title">
              {this.state.requestData.deliveryRequest.itemName}
            </div>
            <div className="item-info-content-description">
              {this.state.requestData.deliveryRequest.itemDesc}
            </div>
          </div>
          <div className="item-info-creation-date w30">
            {this.state.requestData.deliveryRequest.creationDate
              .toDate()
              .toLocaleString('en-NZ')}
          </div>
        </div>
      );
    }
  }

  displayRequestItemDetails() {
    if (!this.state.requestData) {
      return <ShimmerLoader />;
    } else {
      return <div></div>;
    }
  }

  displayPickupInfo() {
    if (!this.state.requestData) {
      return <ShimmerLoader />;
    } else {
      return (
        <div className="request-item-info-card w50 margin7right">
          <div className="address-details-container w40">
            <div className="address-details-address-header">
              Pick up Address
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.pickUpAddress.street}
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.pickUpAddress.suburb
                ? this.state.requestData.deliveryRequest.pickUpAddress.suburb
                : ''}
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.pickUpAddress.city +
                ', ' +
                this.state.requestData.deliveryRequest.pickUpAddress.postcode}
            </div>
          </div>
          <div className="address-details-map w40"></div>
        </div>
      );
    }
  }

  displayDeliveryInfo() {
    if (!this.state.requestData) {
      return <ShimmerLoader />;
    } else {
      return (
        <div className="request-item-info-card w50 margin7left">
          <div className="address-details-container w40">
            <div className="address-details-address-header">
              Delivery Address
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.dropOffAddress.street}
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.dropOffAddress.suburb
                ? this.state.requestData.deliveryRequest.dropOffAddress.suburb
                : ''}
            </div>
            <div className="address-details-address">
              {this.state.requestData.deliveryRequest.dropOffAddress.city +
                ', ' +
                this.state.requestData.deliveryRequest.dropOffAddress.postcode}
            </div>
          </div>
          <div className="address-details-map w40"></div>
        </div>
      );
    }
  }

  displayTravellerDetails() {
    if (!this.state.userData) {
      return <ShimmerLoader />;
    } else {
      return (
        <div className="request-item-info-card">
          <div className="traveller-details-container">
            <div className="details-title">
              <div className="title-content">Traveller Details</div>
            </div>
            <div className="traveller-details">
              <div className="details-img">
                {this.state.userData.profileImgUrl ? (
                  <img
                    className="user-profile-image"
                    src={this.state.userData.profileImgUrl}
                  />
                ) : (
                  <i className="far fa-user-circle"></i>
                )}
              </div>
              <div className="info">
                <div className="info-content bold-title">
                  {this.state.userData.displayName}
                </div>
                <div className="info-content">
                  <Ratings
                    rating={calculateRating(
                      this.state.userData.totalRating,
                      this.state.userData.numberOfRatings
                    )}
                    widgetRatedColors="rgb(0, 72, 108)"
                    widgetDimensions="16px"
                    widgetSpacings="2px"
                  >
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                  </Ratings>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  displayPickupImage() {
    if (!this.state.userData) {
      return <ShimmerLoader />;
    } else if (
      this.state.requestData.status < 3 ||
      this.state.requestData.status != 7
    ) {
      return (
        //pickUpImageUri
        <div className="request-item-info-card margin7right">
          <div className="address-details-container w100">
            <div className="address-details-address-header">Pick Up Image</div>
            <div className="pickup-delivery-image-container">
              Pickup has not been completed yet
            </div>
          </div>
        </div>
      );
    } else if (this.state.requestData.status == 7) {
      return <></>;
    } else {
      return (
        <div className="request-item-info-card margin7right">
          <div className="address-details-container w100">
            <div className="address-details-address-header">Pick Up Image</div>
            <div className="pickup-delivery-image-container">
              <img
                className="pickup-delivery-image"
                src={this.state.requestData.pickUpImageUri}
                alt="No Image"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  displayDropOffImage() {
    if (!this.state.userData) {
      return <ShimmerLoader />;
    } else if (
      this.state.requestData.status < 4 ||
      this.state.requestData.status != 7
    ) {
      return (
        //pickUpImageUri
        <div className="request-item-info-card margin7left">
          <div className="address-details-container w100">
            <div className="address-details-address-header">Drop off Image</div>
            <div className="pickup-delivery-image-container">
              Delivery has not been completed yet
            </div>
          </div>
        </div>
      );
    } else if (this.state.requestData.status == 7) {
      return <></>;
    } else {
      return (
        <div className="request-item-info-card margin7left">
          <div className="address-details-container w100">
            <div className="address-details-address-header">Drop off Image</div>
            <div className="pickup-delivery-image-container">
              <img
                className="pickup-delivery-image"
                src={this.state.requestData.deliveryImageUri}
                alt="No Image"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.fetchRequest(this.state.userId, this.state.dir, this.state.requestId);
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
      if (!user && this.subscribe) {
        this.subscribe();
      }
    });
  }

  render() {
    return (
      <div style={{ padding: '30px' }}>
        <Spinner />
        <Helmet>
          <title>
            {this.state.dir} Request | {this.state.requestId}
          </title>
          <meta name="description" content="Trippie Air | User Profiles" />
        </Helmet>
        <div>
          <h1 className="control-title">
            {this.state.dir} Request | ID: {this.state.requestId}
          </h1>
          <div className="request-content">
            <div className="request-item-info">
              {this.displayProgressionBar()}
            </div>
            <div className="request-item-info">{this.displayRequestInfo()}</div>
            <div>{this.displayRequestItemDetails()}</div>
            <div className="pickup-dropoff-container">
              {this.displayPickupInfo()}
              {this.displayDeliveryInfo()}
            </div>
            <div className="pickup-dropoff-images-container">
              {this.displayPickupImage()}
              {this.displayDropOffImage()}
            </div>
            <div>{this.displayTravellerDetails()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestDetails;
