import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { Helmet } from 'react-helmet';

import { spinner, showConfirmDialog } from '../../App';
import { auth, functions, firestore } from '../../Firebase';
import { FaTruckPickup } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import Spinner from '../ModalComponents/Spinner';
import { getStatus } from '../../utils/helper';
import GreenCheck from '../../img/greencheck.png';
import RedCheck from '../../img/redcross.png';

class TripDetails extends Component {
  constructor(props) {
    super(props);
    this.pending = true;
    this.listener = null;
    this.state = {
      tripId: window.location.href.split('/').at(-2),
      senderId: window.location.href.split('/').at(-1),
      tripData: null,
      authTripCheck: false,
      declineTripCheck: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchData(tripId) {
    if (this.listener) {
      this.listener(); // Unsubscribe previous listener
    }

    // Store reference to current listener
    this.listener = firestore
      .collection('delivery_requests')
      .doc(this.state.senderId)
      .collection('requests_stored')
      .doc(tripId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          // Use arrow function to maintain correct context of `this`
          this.setState({
            tripData: doc.data(),
          });
        } else {
          if (this.pending) {
            this.pending = false;
            this.fetchData(tripId);
          } else {
            // Handle case where document doesn't exist
            spinner(true);
            showConfirmDialog(true, 'This trip no longer exists!');
          }
        }
      });
  }

  handleInputChange(event) {
    const { name, value, type, checked } = event.target;

    type === 'checkbox'
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value });
  }

  handleAuth(value) {
    if (value === 'declineTripCheck') {
      this.declineTrip(this.state.tripId);
    }
    if (value === 'authTripCheck') {
      this.authTrip(this.state.tripId, this.state.senderId);
    }
  }

  authTrip(tripId, senderId) {
    spinner(true);

    var authorisePendingTrip = functions.httpsCallable('authorisePendingTrip');
    spinner(true);
    authorisePendingTrip({ requestId: tripId, senderId: senderId })
      .then(() => {
        showConfirmDialog(false, 'Trip has been authorised!');
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  }

  declineTrip(tripId) {
    spinner(true);

    var declinePendingTrip = functions.httpsCallable('declinePendingTrip');
    spinner(true);
    declinePendingTrip({ requestId: tripId, senderId: this.state.senderId })
      .then(() => {
        showConfirmDialog(false, 'Trip has been cancelled!');
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  }

  handleAuthButtons(tripData) {
    if (tripData?.status === 1) {
      return (
        <div className="authorisation-controls">
          <div className="auth-50-percent auth-btn-box">
            <div
              className="confirmation-container"
              onClick={() => this.handleAuth('authTripCheck')}
            >
              <img src={GreenCheck} alt="accept" className="auth-icons" />
              <div className="confirmation-btn-container">Accept</div>
            </div>
            {/* <div className="confirmation-label-container">
              <label htmlFor="confirm">Accept</label>
            </div> */}
          </div>
          <div className="auth-50-percent auth-btn-box">
            <div
              className="confirmation-container"
              onClick={() => this.handleAuth('declineTripCheck')}
            >
              <img src={RedCheck} alt="accept" className="auth-icons" />
              <div className="confirmation-btn-container">Reject</div>
            </div>
          </div>
        </div>
      );
    } else return;
  }

  renderBags(item, i) {
    return (
      <>
        <div className="baggage-details-header">
          <div className="header-bag-type details-img">
            <div
              className={`${
                item?.isUrgent === 'true' ? 'cancellation-reason' : ''
              }`}
            >
              {item?.isUrgent === 'true' ? 'U' : 'R'}
            </div>
          </div>
          <div className="header-bag-dimensions details-img">
            <div>Height: {item.itemHeight}cm</div>
            <div>Width: {item.itemWidth}cm</div>
            <div>Depth: {item.itemDepth}cm</div>
          </div>
          <div className="header-available-weight details-img">
            <div>{item.itemWeight}kg</div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.fetchData(this.state.tripId);
    auth.onAuthStateChanged((user) => {
      if (!user && this.listener) {
        this.listener();
      }
    });
  }

  render() {
    if (!this.state.tripData) return <></>;
    const bags = this.renderBags(this.state.tripData);
    return (
      <div className="trip-details-background" id="loadTripDetails">
        <Spinner />
        <Helmet>
          <title>Trip Details | TripID: {this.state.tripId}</title>
          <meta
            name="description"
            content="Trippie Air | Authorise new trips"
          />
        </Helmet>
        <div className="trip-details-container">
          <h1 className="control-title">Detailed Delivery Information</h1>
          <div className="data-container">
            <div className="box-title-container">
              <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                {this.handleAuthButtons(this.state.tripData)}
              </div>
            </div>
            <div className="data-row width-100">
              <div className="trip-data">
                <div className="delivery-info " id="tripFlightDetails">
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">Id</div>
                    <div className="info-content">{this.state.tripId}</div>
                  </div>
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">Item Name</div>
                    <div className="info-content">
                      {this.state.tripData?.itemName}
                    </div>
                  </div>
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">Payment Type</div>
                    <div className="info-content">
                      {this.state.tripData?.paymentType.toUpperCase()}
                    </div>
                  </div>
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">Payment Done</div>
                    <div className="info-content">
                      {this.state.tripData?.paymentSuccess === 1
                        ? 'Success'
                        : 'Pending'}
                    </div>
                  </div>
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">
                      Payment Amount
                    </div>
                    <div className="info-content">
                      {this.state.tripData?.paidAmount / 100 ||
                        this.state.tripData?.price}
                    </div>
                  </div>
                  <div className="content-card" style={{ display: 'flex' }}>
                    <div className="info-content bold-title">Status</div>
                    <div className="info-content">
                      <b>{getStatus(this.state.tripData?.status)}</b>
                    </div>
                  </div>
                </div>
                <div id="tripFlightDetails">
                  <div className="depart-arrive-details">
                    <div className="depart-details">
                      <div className="details-img">
                        <FaTruckPickup size={50} color="#01a2cc" />
                      </div>
                      <div className="info" style={{ display: 'block' }}>
                        <div className="info-content bold-title">
                          Pickup Address
                        </div>
                        <div className="info-content">
                          {`${this.state.tripData.pickUpAddress.street}, ${this.state.tripData.pickUpAddress.city}, ${this.state.tripData.pickUpAddress.postcode}`}
                        </div>
                        <div className="info-content">
                          {this.state.tripData.pickUpDateTime ? 
                            new Date(this.state.tripData.pickUpDateTime.seconds * 1000 + 
                              this.state.tripData.pickUpDateTime.nanoseconds / 1e6).toLocaleString('en-NZ') : '---' }
                        </div>
                      </div>
                    </div>
                    <div className="arrive-details">
                      <div className="details-img">
                        <TbTruckDelivery size={50} color="#01a2cc" />
                      </div>
                      <div className="info" style={{ display: 'block' }}>
                        <div className="info-content bold-title">
                          Dropoff Address
                        </div>
                        <div className="info-content">
                          {`${this.state.tripData.dropOffAddress.street}, ${this.state.tripData.dropOffAddress.city}, ${this.state.tripData.dropOffAddress.postcode}`}
                        </div>
                      </div>
                      {this.state.tripData?.distance && (
                        <div className="distance">
                          Distance: {this.state.tripData?.distance} KM
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div id="tripBagDetails">
                  <div className="traveller-details-container">
                    <div className="details-title">
                      <div className="title-content">Details</div>
                    </div>
                    <div className="baggage-details">
                      <div className="baggage-details-header">
                        <div className="header-bag-type details-img">
                          <i className="fas fa-suitcase"></i>
                          <div> Type</div>
                        </div>
                        <div className="header-bag-dimensions details-img">
                          <i className="fas fa-ruler-combined"></i>
                          <div> Dimensions</div>
                        </div>
                        <div className="header-available-weight details-img">
                          <i className="fas fa-dumbbell"></i>
                          <div>Weight</div>
                        </div>
                      </div>
                      {bags}
                    </div>
                  </div>
                </div>
                <div id="tripTravellerDetails">
                  <div className="traveller-details-container">
                    <div className="details-title">
                      <div className="title-content">Customer Details</div>
                    </div>
                    <div className="traveller-details">
                      <div className="details-img">
                        {this.state.tripData?.senderDisplayImg ? (
                          <img
                            className="user-profile-image"
                            src={this.state.tripData?.senderDisplayImg}
                            alt="user-profile"
                          />
                        ) : (
                          <i className="far fa-user-circle"></i>
                        )}
                      </div>
                      <div className="info">
                        <div className="info-content bold-title">
                          {this.state.tripData?.senderDisplayName}
                        </div>

                        <div className="info-content">
                          Listed{' '}
                          {this.state.tripData.creationDate ? 
                          new Date(this.state.tripData.creationDate.seconds * 1000 + 
                            this.state.tripData.creationDate.nanoseconds / 1e6).toLocaleString('en-NZ') : '---'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tripTravellerDetails">
                  <div className="traveller-details-container">
                    <div className="details-title" style={{ marginBottom: 10 }}>
                      <div className="title-content">Receiver Details</div>
                    </div>

                    <div className="delivery-info ">
                      <div className="content-card" style={{ display: 'flex' }}>
                        <div className="info-content bold-title">
                          Receiver Name
                        </div>
                        <div className="info-content">
                          <b>{this.state.tripData?.itemReceiverName}</b>
                        </div>
                      </div>
                      <div className="content-card" style={{ display: 'flex' }}>
                        <div className="info-content bold-title">
                          Receiver Phone
                        </div>
                        <div className="info-content">
                          <b>{this.state.tripData?.itemReceiverNo}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-trip-data-image">
                <div id="tripItineraryDetails">
                  <img src={this.state.tripData?.imageUri} alt="trip-profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TripDetails);
