import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { functions } from '../../Firebase';
import { showConfirmDialog, spinner } from '../../App';
import { Col, Form, Row } from 'react-bootstrap';

const DriverProfile = ({ openModel, setOpenModel, userId, data }) => {
  console.log(data, 'data');
  const [form, setForm] = useState({
    licenseNo: data?.licenseNo || '',
    licenseExp: data?.licenseExp || '',
    idExpiration: data?.licenseExp || '',
  });

  function handleInputChange(event) {
    const target = event.target;
    const { name, value } = event.target;
    console.log({ name, target }, '----name ,target');
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  const handleSubmit = () => {
    var updateProfile = functions.httpsCallable('updateUserProfileInfo');
    let data = {
      traveller: form,
      type: 'traveller',
      userId: userId,
    };
    spinner(true);
    updateProfile(data)
      .then(() => {
        showConfirmDialog(
          false,
          'Driver profile has been updated successfully.'
        );
        setOpenModel(false);
      })
      .catch((error) => {
        showConfirmDialog(true, error.message);
      });
  };

  useEffect(() => {
    setForm(data);
  }, [data]);

  const handleClose = () => setOpenModel(false);
  return (
    <>
      <Modal show={openModel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Driver Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-ban-user-content">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>License No.</Form.Label>
              <Form.Control
                type="text"
                className="user-data-content-info-input"
                name="licenseNo"
                value={form?.licenseNo}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>License Exp. </Form.Label>
              <Form.Control
                type="date"
                className="user-data-content-info-input"
                name="licenseExp"
                value={form?.licenseExp}
                onChange={handleInputChange}
                min={new Date().toISOString().split('T')[0]} // Sets min to today's date
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Id Expiration Date. </Form.Label>
              <Form.Control
                type="date"
                className="user-data-content-info-input"
                name="idExpiration"
                value={form?.idExpiration}
                onChange={handleInputChange}
                min={new Date().toISOString().split('T')[0]} // Sets min to today's date
              />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            onClick={() => handleClose(false)}
            className="user-action-button ban-user-btn noselect"
          >
            Cancel
          </div>

          <div
            onClick={handleSubmit}
            className="user-action-button save-user-btn noselect"
          >
            Save
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DriverProfile;
