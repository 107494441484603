import React from "react";

const Error = ({ message }) => {
  return (
    <div className="error-container">
      <div className="error-icon">⚠️</div>
      <h2 className="error-title">Oops! Something went wrong</h2>
      <p className="error-message">{message}</p>
      <p className="error-note">
        Please check your URL and try again. If the problem persists, contact
        our support team.
      </p>
      <button className="retry-button" onClick={() => window.location.reload()}>
        Try Again
      </button>
    </div>
  );
};

export default Error;
