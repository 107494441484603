import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import {auth} from '../../Firebase';
import {UserContext} from '../../Providers/UserProvider';

import Logo from '../../img/trippie_logo_white.png';
import LogoPlane from '../../img/trippie-air-plane.svg';
import TestImage from '../../img/login-bg.jpg';

import NavTree from '../Navigation/NavTreeView';
import NotificationsBell from '../Navigation/NotificationsBell';

var navHeaderElements = document.getElementsByClassName('nav-header');
var menuItemText = document.getElementsByClassName('menu-item-text');

let timeOut;

export default class Navigation extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            menuLogo: Logo,
            openUserSettings: false,
        };
    }

    // TODO: Rework openCloseNav function - Phase 2 of Web Portal
    openCloseNav() {
        const menuBtn = document.querySelector('.menu-btn');
        if (!this.state.menuOpen) {
            menuBtn.classList.add('open');
            this.setState({
                menuOpen: true,
                menuLogo: LogoPlane,
            });
            document.getElementById('main-sidebar').style.width = '61px';
            document.getElementById('wrapper').style.paddingLeft = '61px';
            document.getElementById('footer').style.margin = '0 0 0 61px';
            for (var i = 0; i < navHeaderElements.length; i++) {
                navHeaderElements[i].style.display = 'none';
            }
            for (var l = 0; l < menuItemText.length; l++) {
                menuItemText[l].style.display = 'none';
            }
        } else {
            menuBtn.classList.remove('open');
            this.setState({
                menuOpen: false,
                menuLogo: Logo,
            });
            document.getElementById('main-sidebar').style.width = '250px';
            document.getElementById('wrapper').style.paddingLeft = '250px';
            document.getElementById('footer').style.margin = '0px 0px 0px 250px';
            setTimeout(function () {
                for (var i = 0; i < navHeaderElements.length; i++) {
                    navHeaderElements[i].style.display = 'list-item';
                }
                for (var l = 0; l < menuItemText.length; l++) {
                    menuItemText[l].style.display = 'unset';
                }
            }, 250);
        }
    }

    async signOut() {
        try {
            await auth.signOut();
            console.log('logout successfully');
        } catch (error) {
            console.log('err', error);
        }
    }

    openUserSettings() {
        var dropDown = document.getElementById('userDropdownSettings');
        var dropDownItems = document.getElementsByClassName('drop-down-item');
        var downChevronUser = document.getElementById('downChevronUser');
        if (!this.state.openUserSettings) {
            this.setState({openUserSettings: true});
            dropDown.style.height = '100px';
            timeOut = setTimeout(function () {
                for (var i = 0; i < dropDownItems.length; i++) {
                    dropDownItems[i].style.opacity = '1';
                    dropDownItems[i].style.display = 'block';
                }
            }, 250);
            downChevronUser.style.transform = 'rotate(180deg)';
        } else {
            clearTimeout(timeOut);
            this.setState({openUserSettings: false});
            dropDown.style.height = '0px';
            for (var i = 0; i < dropDownItems.length; i++) {
                dropDownItems[i].style.opacity = '0';
                dropDownItems[i].style.display = 'none';
            }
            downChevronUser.style.transform = 'rotate(0deg)';
        }
    }

    componentDidMount() {
    }

    render() {
        const user = this.context;

        return (
            <div>
                <nav className="main-nav" id="mainNav">
                    <div style={{display: 'flex', flexDirection: 'row', width: '40%'}}>
                        <div className="menu-btn" onClick={() => this.openCloseNav()}>
                            <div className="menu-btn-burger"></div>
                        </div>
                    </div>
                    <div className="user-info-navigation">
                        <NotificationsBell/>
                        <div
                            className="user-info noselect"
                            onClick={() => this.openUserSettings()}
                        >
                            <img src={TestImage} alt=""/>
                            <div className="users-name">
                                {user.displayName}
                                <span>
                  <i id="downChevronUser" className="fas fa-chevron-down"></i>
                </span>
                            </div>
                        </div>
                        <div className="user-dropdown-content" id="userDropdownSettings">
                            <div>
                                <NavLink
                                    to="/UserProfile"
                                    className="drop-down-item user-dropdown-link"
                                >
                                    My Profile
                                </NavLink>
                            </div>
                            <div
                                onClick={this.signOut}
                                className="drop-down-item log-out-button"
                            >
                                Logout
                            </div>
                        </div>
                    </div>
                </nav>
                <aside id="main-sidebar" className="main-sidebar dark-bg">
                    <NavLink to="/" className="logo-link">
                        <img
                            src={this.state.menuLogo}
                            alt="Trippie Air"
                            className="logo-img"
                        />
                    </NavLink>
                    <div className="sidebar">
                        <nav>
                            <ul>
                                <li className="nav-item">
                                    <NavLink to="/" className="nav-link">
                                        <i className="fas fa-tachometer-alt"></i>
                                        <span className="menu-item-text">Dashboard</span>
                                    </NavLink>
                                </li>
                                {!user.isThirdParty && (
                                    <>
                                        <li className="nav-header">
                                            <span className="menu-item-text">Trip Controls</span>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/ActiveTrips" className="nav-link">
                                                <i className="fas fa-plane-arrival"></i>
                                                <span className="menu-item-text">
                          Active Deliveries
                        </span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-header">
                      <span className="menu-item-text">
                        Verification Controls
                      </span>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/AuthNewTrips" className="nav-link">
                                                <i className="fas fa-suitcase-rolling"></i>
                                                <span className="menu-item-text">
                          Authorise New Deliveries
                        </span>
                                            </NavLink>
                                        </li>
                                        {!user.isThirdParty && (
                                            <li className="nav-item">
                                                <NavLink to="/AuthNewCompany" className="nav-link">
                                                    <i className="fas fa-suitcase-rolling"></i>
                                                    <span className="menu-item-text">
                            Authorise New Company
                          </span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </>
                                )}

                                <li className="nav-header">
                                    <span className="menu-item-text">User Management</span>
                                </li>
                                {!user.isThirdParty && (
                                    <li className="nav-item">
                                        <NavLink to="/logistic-companies" className="nav-link">
                                            <i className="fas fa-suitcase-rolling"></i>
                                            <span className="menu-item-text">Logistic Companies</span>
                                        </NavLink>
                                    </li>
                                )}

                                {user.isSuperAdmin && (
                                    <li className="nav-item">
                                        <NavLink to="/AdminUsers" className="nav-link">
                                            <i className="fas fa-user-tie"></i>
                                            <span className="menu-item-text">Admin Accounts</span>
                                        </NavLink>
                                    </li>
                                )}

                                <li className="nav-item">
                                    <NavLink to="/Customers" className="nav-link">
                                        <i className="fas fa-user"></i>
                                        <span className="menu-item-text">Users Accounts</span>
                                    </NavLink>
                                </li>
                                {!user.isThirdParty && (
                                    <NavTree
                                        data={{
                                            title: 'Support',
                                            icon: 'fas fa-ticket-alt',
                                            links: [
                                                'General Messages',
                                                'Customer Complaints',
                                                'Reported Listings',
                                            ],
                                            chevronId: 'customerComplaintsChevron',
                                        }}
                                    />
                                )}

                                <li className="nav-header">
                                    <span className="menu-item-text">Financials</span>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Earnings" className="nav-link">
                                        <i className="fas fa-money-check"></i>
                                        <span className="menu-item-text">Earnings</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Payments" className="nav-link">
                                        <i className="fas fa-money-bill"></i>
                                        <span className="menu-item-text">Payments</span>
                                    </NavLink>
                                </li>
                                {!user.isThirdParty && (
                                    <>
                                        <li className="nav-header">
                      <span className="menu-item-text">
                        TrippieAir Web Controls
                      </span>
                                        </li>
                                        <NavTree
                                            data={{
                                                title: 'Web Controls',
                                                icon: 'fas fa-desktop',
                                                links: ['FAQs', 'Other Settings'],
                                                chevronId: 'webControlsChevron',
                                            }}
                                        />
                                    </>
                                )}
                            </ul>
                        </nav>
                    </div>
                </aside>
            </div>
        );
    }
}
