import React, {Component} from 'react';
import {Helmet} from 'react-helmet';

import Spinner from './ModalComponents/Spinner';
import TrippieLogo from '../img/trippie_logo_white.png';
import {auth} from '../Firebase';
import {spinner, showConfirmDialog} from '../App';
import DefaultErrorDialog from "./Models/default-error-dialog";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorOpen: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.signInWithEmailAndPasswordHandler =
            this.signInWithEmailAndPasswordHandler.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    signInWithEmailAndPasswordHandler() {
        spinner(true);
        auth
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .catch((error) => {
                spinner(false);
                this.setState({errorOpen: true});
            });
    }

    componentDidMount() {
        let input = document.getElementById('input');
        input.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                this.signInWithEmailAndPasswordHandler();
            }
        });
    }

    render() {
        return (
            <div className="container-fluid whitesmoke login-page">
                <Helmet>
                    <title>Trippie Air | Login</title>
                    <meta name="description" content="Trippie Air | Login"/>
                </Helmet>
                <Spinner/>
                <div className="container">
                    <div className="test-class">
                        <img
                            className="trippie-air-logo-login-page"
                            src={TrippieLogo}
                            alt="TrippieAir"
                        />
                        <div className="login-box">
                            <div>
                                <div className="login-form">
                                    <div className="login-message">
                                        Sign in to access dashboard
                                    </div>
                                    <div>
                                        <input
                                            required={true}
                                            type="text"
                                            placeholder="Email address"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            id="input"
                                            required={true}
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => this.signInWithEmailAndPasswordHandler()}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DefaultErrorDialog open={this.state.errorOpen}
                                    title={"Login Error"}
                                    message={"You have entered invalid user name or password; please try again"}
                                    handleClose={() => {
                                        this.setState({errorOpen: false});
                                    }}/>
            </div>
        );
    }
}
